import React from "react";
import commonStyles from "@styles/common";
import { css } from "@emotion/react";
import propTypes from "prop-types";

Content.propTypes = {
  isChange: propTypes.number,
};

export default function Content({ isChange }) {
  return (
    <>
      <div css={item({ isChange })} className="item1">
        <label>
          DCS v1.0 Ai-based data automated analysis and development platform
        </label>
        <p>
          DCS supports developers, individuals, companies, and institutions to
          obtain desired AI analysis results more easily by accessing data owned
          or available externally.
          <br />
          <br />
          DCS (D-Cloud Contents Service) is a service platform that supports
          data analysis and development using AI technology in a distributed
          cloud environment. DCS applies AI to data by using the computing
          resources (CPU, GPU, Memory, Storage) of the D-Cloud hardware that
          have been established in advance to derive results in fields requiring
          high accuracy and performing predictions based on existing data.
          <br />
          <br />
          In addition, it supports efficient decision-making by comprehensively
          analyzing data and using the results as a basis for judgment. It has
          Tensorflow, Pytorch, and Mxnet, the world's top three AI frameworks,
          allowing the usage of data analysis services in the form of
          development and automated pipelines directly in the web environment
          without installing a complex AI framework.
          <br />
          <br />
          Therefore, even general users who are not AI experts can use the
          service very easily, and its biggest strength is that they can
          approach AI more familiarly.
        </p>
      </div>
      <div css={item({ isChange })} className="item2">
        <label>AI Data Sharing Platform (Deplay)</label>
        <p>
          DePlay is a platform that uploads all data that requires AI analysis
          and shares it for free discussion.
          <br />
          <br />
          It aims to classify by category so that users can easily find the data
          they want, share the analyzed results, conduct data analysis in
          connection with the DCS platform, and increase data utilization.
          <br />
          <br />
          One of the biggest restrictions in the AI field is the lack of data.
          It is known that many companies have great difficulties in securing
          usable data. It is no exaggeration to say that the key to AI
          competitiveness lies in data.
          <br />
          <br />
          Even if the learning algorithm is good, if the amount of data required
          for learning is insufficient, it will give poor results. Therefore,
          the issue of securing data from independents or companies is the most
          important thing that leads the project to success. DePlay is a
          platform for solving these problems.
          <br />
          <br />
          As a platform for freely uploading and sharing data required for AI
          analysis, it performs data analysis in connection with the DCS
          platform and aims to increase the utilization of AI data by allowing
          users to share and discuss analysis results with each other.
        </p>
      </div>
      <div css={item({ isChange })} className="item3">
        <label>Petcare Service Platform</label>
        <p>
          Petcarelab is a startup that specializes in developing and operating
          the pet platform. Its main business is to develop a healthcare
          platform for pet health management and disease prevention.
          <br />
          <br />
          Petcarelab platform is a product aimed at healthy pet families' lives
          through early detection and prevention of pet diseases using smart pee
          pads at home without visiting vets and is developing products using
          smart pee pads and AI-based analysis technology.
          <br />
          <br />
          Recently, with the increase in single-person households, low
          birthrate, and aging population, the number of consumers who spend
          time and invest in pets instead of having children continues to
          increase.
          <br />
          <br />
          The global pet market is growing rapidly as the trend of "Pet
          Humanization," which considers pets as family members, and "Pet-Tech"
          to enhance the happiness and convenience of pet lovers and pets is
          spreading, resulting in sharp growth of the global pet market. In
          particular, with the advent of the big data era in the pet industry,
          'Pet Tech' is attracting attention as a blue ocean market.
          <br />
          <br />
          Therefore, the potential for future growth is expected to be very
          high.
        </p>
      </div>
      <div css={item({ isChange })} className="item4">
        <label>Real Estate NFT Investment Platform</label>
        <p>
          The Metaglobe platform aims to acquire real estate stock shares by
          NFTizing stocks of a real estate management corporation, allocation of
          operating income and trading profits as much as the invested shares,
          and free trading of the investment shares and NFTs.
          <br />
          <br />
          Despite the government's recent efforts to increase the needs of
          general investors in real estate investment and expand real estate
          indirect investment, it is difficult to provide opportunities for
          indirect real estate investment for general investors, not
          institutions and companies.
          <br />
          <br />
          However, we operate a real estate indirect investment platform that
          meets the needs of the current market through the stock investment of
          real estate management corporations in combination with blockchain and
          NFT.
          <br />
          <br />
          Metaglobe differs from other investment platforms through indirect
          investment through NFT of real estate (profitable real estate),
          customized small and medium-sized hotel remodeling (business initial
          model), fast refundability, and various profit vehicles.
        </p>
      </div>
      <div css={item({ isChange })} className="item5">
        <label>Decentralized P2P Exchange</label>
        <p>
          The Decentralized P2P Exchange (DEX) is a decentralized cryptocurrency
          exchange operating in a financial trading (P2P) method between virtual
          currency users and individuals and aims to connect the trading parties
          to each other without intermediaries directly.
          <br />
          <br />
          In general, exchanges such as Upbit and Bithumb, where many investors
          trade, are centralized exchanges (CEX), which have a centrally managed
          operating entity, charge certain trading fees and provide security for
          individuals, and are not free from security risks because it stores
          the security key of individuals in Exchanges.
          <br />
          <br />
          After the massive cryptocurrency leak, hardware wallets and
          decentralized exchanges, where users manage their cryptocurrency, are
          attracting attention. According to the latest report from Citibank in
          October 2022, decentralized exchanges are growing faster than
          centralized exchanges over the past two years due to increased
          regulations, and more users move to decentralized exchanges to avoid
          KYC.
          <br />
          <br />
          The volume is 18 of the spot trading volume. The decentralized
          exchange's monthly trading volume has been confirmed to amount to $50
          billion, or 18.2% of the spot trading volume.
        </p>
      </div>
      <div css={item({ isChange })} className="item6">
        <label>Coin Shopping mall</label>
        <p>
          Exchanges users will sell at the amount they want and have to wait for
          the buyers, but in the case of actual minor coins, the difference
          between selling and buying is large, so a loss occurs even if the sale
          proceeds.
          <br />
          <br />
          The lowest-priced coin shopping mall provides a shopping mall where
          members can purchase products using coins that are not actively traded
          among the coins they are holding and registered products at a lower
          price than other companies. Payment can be made with existing payment
          vehicles such as cash and cards, and coins converted into points.
          <br />
          <br />
          Aims to give real value to users by providing an online shopping mall
          where they can purchase products necessary for real life at the lowest
          price with coins without current use value.
          <br />
          <br />
          Unlike the existing shopping malls that pay points when purchasing
          products or meeting payment conditions, the Lowest price coin shopping
          mall allows users to use their holding coins like cash.
        </p>
      </div>
      <div css={item({ isChange })} className="item7">
        <label>Distributed Cloud-based mining business</label>
        <p>
          Distributed cloud-based mining business aims to store data and share
          data needed for AI technology through IPFS (Interplanetary File
          System) technology by distributing Filecoin mining-enabled cloud
          hardware to individuals to lay the foundation for AI technology
          throughout AIDAPPS.
          <br />
          <br />
          Recently, Filecoin has been expanding from first-stage block mining to
          second-stage storage mining.
          <br />
          <br />
          The second stage of storage mining requires proving storage space
          through actual data, and data from various AI technology platforms of
          AIDAPPS will be used to prove storage space.
          <br />
          <br />
          The goal is to deploy distributed cloud hardware to lay the foundation
          for AI technology that will be used on the entire AIDAPPS platform and
          to build a distributed cloud foundation by utilizing AI data from that
          platform as storage mining data and Filecoin storage mining.
        </p>
      </div>
    </>
  );
}

const { colors, font } = commonStyles;

const item = ({ isChange }) => css`
  padding: 24px 0;

  &.item1 {
    display: ${isChange === 0 ? "block" : "none"};
  }

  &.item2 {
    display: ${isChange === 1 ? "block" : "none"};
  }

  &.item3 {
    display: ${isChange === 2 ? "block" : "none"};
  }

  &.item4 {
    display: ${isChange === 3 ? "block" : "none"};
  }
  &.item5 {
    display: ${isChange === 4 ? "block" : "none"};
  }
  &.item6 {
    display: ${isChange === 5 ? "block" : "none"};
  }
  &.item7 {
    display: ${isChange === 6 ? "block" : "none"};
  }

  label {
    font-size: 19px;
    font-weight: ${font.weight.bold};
    margin-bottom: 16px;
  }

  p {
    font-size: 14px;
    color: ${colors.gray};
    line-height: 1.4;
  }

  @media screen and (min-width: 1024px) {
    padding: 40px 0;

    label {
      font-size: 30px;
      margin-bottom: 30px;
    }

    p {
      font-size: 18px;
    }
  }
`;
