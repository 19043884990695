import React from "react";
import { ToTop } from "@components";
import logo from "@images/footer_logo.png";
import { MobileOrTablet, Laptop } from "@utils/responsive";
import { css } from "@emotion/react";
import commonStyles from "@styles/common";

export default function Footer() {
  return (
    <>
      <footer css={footer}>
        <div css={inner}>
          <a href="/">
            <img src={logo} alt="logo" className="logo" />
          </a>
          {/* <div css={inquiry}>
            <MobileOrTablet>
              <span>Inquiry : </span>
            </MobileOrTablet>
            <Laptop>
              <span>Inquiry</span>
            </Laptop>
            <a href="mailto:strategy@ai-dapps.com">strategy@ai-dapps.com</a>
            <p>For inquiries, please email us.</p>
          </div> */}
          {/* <address css={address}>
            <MobileOrTablet>
              Address : 4F Ian Bldg., 41, Nonhyeon-ro 106-gil, Gangnam-gu, Seoul
              | CEO : Heo Tain | Call : 02-6925-2218 Business registration
              number : 118-81-33489 Business E-mail :{" "}
              <a href="mailto:strategy@ai-dapps.com">strategy@ai-dapps.com</a>
            </MobileOrTablet>
            <Laptop>
              Address : 4F Ian Bldg., 41, Nonhyeon-ro 106-gil, Gangnam-gu, Seoul
              | CEO : Heo Tain | Call : 02-6925-2218 <br /> Business
              registration number : 118-81-33489 Business E-mail :{" "}
              <a href="mailto:strategy@ai-dapps.com">strategy@ai-dapps.com</a>
            </Laptop>
          </address> */}
          <p css={copy}>&copy;Copyright AGC Co., Ltd. All rights reserved.</p>
        </div>
      </footer>
      <ToTop />
    </>
  );
}

const { colors, font } = commonStyles;

const footer = css`
  padding: 45px 20px;
  background-color: ${colors.indigo};

  .logo {
    margin-bottom: 20px;
  }

  @media screen and (min-width: 1024px) {
    ${"" /* height: 300px; */}
    padding: 70px 20px;
  }
`;

const inquiry = css`
  font-size: 18px;
  font-weight: ${font.weight.bold};
  margin-bottom: 20px;

  span {
    color: ${colors.lightBlue};
  }

  a {
    margin-bottom: 10px;
    color: ${colors.white};
  }

  p {
    color: ${colors.white};
    font-weight: ${font.weight.light};
  }

  @media screen and (min-width: 1024px) {
    position: absolute;
    top: 14px;
    right: 0;

    span {
      display: block;
      margin-bottom: 20px;
    }

    a {
      font-size: 24px;
      margin-bottom: 16px;
    }
  }
`;

const address = css`
  color: ${colors.cacaec};
  font-size: 13px;
  line-height: 1.4;
  margin-bottom: 14px;

  @media screen and (min-width: 1024px) {
    position: absolute;
    left: 0;
    font-size: 15px;
    line-height: 1.6;
  }
`;

const copy = css`
  color: ${colors.cacaec};
  font-size: 13px;

  @media screen and (min-width: 1024px) {
    ${
      "" /* position: absolute;
    bottom: 0;
    left: 0; */
    }
  }
`;

const inner = css`
  width: min(100%, 1200px);
  margin: 0 auto;
  position: relative;

  @media screen and (min-width: 1024px) {
    height: 100%;
  }
`;
