import React from "react";
import Title from "./Title";
import commonStyles from "@styles/common";
import { css } from "@emotion/react";
import propTypes from "prop-types";
import bgImg01 from "@images/relationships_bn.png";
import bgImg02 from "@images/mobile/relationships_bn.png";
import { BigMobile, Tablet } from "@utils/responsive";

Relationships.propTypes = {
  menuRef: propTypes.object,
};

export default function Relationships({ menuRef }) {
  return (
    <section
      css={relationships}
      id="relationships"
      ref={(el) => (menuRef.current[6] = el)}
    >
      <div css={inner}>
        <Title title="Relationships" subTitle="Ai-DAPPS Group Cryptocurrency" />
        <span>The Ai-DAPPS is</span>
        <p>
          Project aims to be an AI blockchain based on a distributed cloud
          (Decentralized Cloud) network environment To this end, build an
          ecosystem that supplies hardware constituting a distributed network
          environment, reward nodes that lend computing resources of hardware,
          and builds various service platforms that operate on a distributed
          network basis. The AGC Foundation has all rights to coin issuance and
          ownership, and has delegated development and operational rights to all
          platforms to AIDAPPS Co., Ltd. to continuously expand the utilization
          and ecosystem of AGC coins.
        </p>
        <BigMobile>
          <img src={bgImg02} alt="The Ai-DAPPS" />
        </BigMobile>
        <Tablet>
          <img src={bgImg01} alt="The Ai-DAPPS" />
        </Tablet>
      </div>
    </section>
  );
}

const { font } = commonStyles;

const relationships = css`
  padding: 45px 20px;

  @media screen and (min-width: 1024px) {
    padding: 130px 20px;
  }
`;

const inner = css`
  width: min(100%, 1200px);
  margin: 0 auto;

  & > span {
    font-size: 36px;
    font-weight: ${font.weight.bold};
    margin-bottom: 24px;
    display: inline-block;
  }

  p {
    font-size: 20px;
    font-weight: ${font.weight.light};
    line-height: 1.6;
    margin-bottom: 80px;
  }

  @media screen and (max-width: 1023px) {
    & > span {
      font-size: 20px;
    }

    p {
      font-size: 15px;
    }
  }
`;
