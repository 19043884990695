import { css } from "@emotion/react";
import commonStyles from "./common";

const { colors } = commonStyles;

const reset = css`
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    font-family: "Roboto", sans-serif;
    color: ${colors.black};
  }

  ul,
  ol,
  li {
    list-style: none;
  }

  a {
    display: inline-block;
    text-decoration: none;
    color: inherit;
    outline: none;
  }

  button {
    border: none;
    outline: none;
    background-color: transparent;
    font-family: "Roboto", sans-serif;
    cursor: pointer;
    color: inherit;
  }

  img {
    max-width: 100%;
    display: block;
  }

  address {
    font-style: normal;
  }

  label {
    display: inline-block;
  }
`;

export default reset;
