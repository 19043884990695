import React from "react";
import Title from "../Title";
import commonStyles from "@styles/common";
import Carousel from "./Carousel";
import { css } from "@emotion/react";
import propTypes from "prop-types";

News.propTypes = {
  menuRef: propTypes.object,
};

export default function News({ menuRef }) {
  return (
    <section css={news} id="news" ref={(el) => (menuRef.current[5] = el)}>
      <div css={inner}>
        <Title title="NEWS" subTitle="AGC announces new news" />
        <Carousel />
      </div>
    </section>
  );
}

const { colors } = commonStyles;

const news = css`
  padding: 45px 20px;

  @media screen and (min-width: 1024px) {
    padding: 130px 20px;
  }
`;

const inner = css`
  width: min(100%, 1200px);
  margin: 0 auto;
`;
