import React from "react";
import reset from "@styles/reset";
import { Global } from "@emotion/react";
import { Container } from "@components";

export default function App() {
  return (
    <>
      <Global styles={reset} />
      <Container />
    </>
  );
}
