import React from "react";
import Title from "./Title";
import commonStyles from "@styles/common";
import { css } from "@emotion/react";
import propTypes from "prop-types";
import twitterLogo from "@images/community/com_twitter.png";
import blogLogo from "@images/community/com_blog.png";
import youtubeLogo from "@images/community/com_youtube.png";
import telegramLogo from "@images/community/com_tele.png";
import kakaoLogo from "@images/community/com_kakao.png";
import gitHubLogo from "@images/community/com_github.png";
import { BigMobile } from "@utils/responsive";
import { MdArrowForwardIos } from "react-icons/md";

Community.propTypes = {
  menuRef: propTypes.object,
};

export default function Community({ menuRef }) {
  return (
    <section
      css={community}
      id="community"
      ref={(el) => (menuRef.current[8] = el)}
    >
      <div css={inner}>
        <Title
          title="Community"
          subTitle="Let's be together The AGC Community"
        />
        <div css={items}>
          <div css={item}>
            <span>Social Media</span>
            <div css={links}>
              <a
                href="https://twitter.com/AGCoofficial"
                target="_blank"
                rel="noreferrer noopenner"
              >
                <img src={twitterLogo} alt="twitter logo" />
                <div>
                  <label>Twitter</label>
                  <span>
                    @ AGCooficial
                    <BigMobile>
                      <MdArrowForwardIos />
                    </BigMobile>
                  </span>
                </div>
              </a>
              <a
                href="https://blog.naver.com/agc_project"
                target="_blank"
                rel="noreferrer noopenner"
              >
                <img src={blogLogo} alt="naver blog logo" />
                <div>
                  <label>Blog</label>
                  <span>
                    @ agc_project
                    <BigMobile>
                      <MdArrowForwardIos />
                    </BigMobile>
                  </span>
                </div>
              </a>
              <a
                href="https://www.youtube.com/channel/UCPVwZmAaBkn6KHXwgkmEtgw"
                target="_blank"
                rel="noreferrer noopenner"
              >
                <img src={youtubeLogo} alt="youtube logo" />
                <div>
                  <label>Youtube</label>
                  <span>
                    @ AGC
                    <BigMobile>
                      <MdArrowForwardIos />
                    </BigMobile>
                  </span>
                </div>
              </a>
            </div>
          </div>
          <div css={item}>
            <span>Online Communities</span>
            <div css={links}>
              <a
                href="https://t.me/agcproject"
                target="_blank"
                rel="noreferrer noopenner"
              >
                <img src={telegramLogo} alt="telegram logo" />
                <div>
                  <label>Telegram</label>
                  <span>
                    @ agcproject
                    <BigMobile>
                      <MdArrowForwardIos />
                    </BigMobile>
                  </span>
                </div>
              </a>
              <a
                href="https://pf.kakao.com/_EpqXxj"
                target="_blank"
                rel="noreferrer noopenner"
              >
                <img src={kakaoLogo} alt="kakaotalk logo" />
                <div>
                  <label>Kakao Talk</label>
                  <span>
                    @ AGC
                    <BigMobile>
                      <MdArrowForwardIos />
                    </BigMobile>
                  </span>
                </div>
              </a>
              <a
                href="https://github.com/AGC-project"
                target="_blank"
                rel="noreferrer noopenner"
              >
                <img src={gitHubLogo} alt="github logo" />
                <div>
                  <label>GitHub</label>
                  <span>
                    @ AGC-project
                    <BigMobile>
                      <MdArrowForwardIos />
                    </BigMobile>
                  </span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const { colors, font } = commonStyles;

const community = css`
  padding: 45px 20px;

  @media screen and (min-width: 1024px) {
    padding: 130px 20px;
  }
`;

const inner = css`
  width: min(100%, 1200px);
  margin: 0 auto;
`;

const items = css``;
const item = css`
  :not(:last-child) {
    margin-bottom: 40px;
  }

  & > span {
    font-size: 22px;
    font-weight: ${font.weight.medium};
    margin-bottom: 30px;
    display: block;
    text-align: center;
  }

  @media screen and (min-width: 1024px) {
    & > span {
      font-size: 34px;
      text-align: left;
    }

    :not(:last-child) {
      margin-bottom: 80px;
    }
  }
`;

const links = css`
  display: flex;
  flex-wrap: wrap;
  gap: 18px;

  a {
    background-color: ${colors.darkWhite};
    flex: auto;
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    border-radius: 20px;

    div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    img {
      margin-right: 26px;
      width: 16%;
    }

    label {
      font-size: 16px;
      font-weight: ${font.weight.bold};
      display: block;
    }

    span {
      font-size: 14px;
      color: ${colors.gray};
      display: flex;
      align-items: center;

      svg {
        margin-left: 10px;
      }
    }
  }

  @media screen and (min-width: 426px) {
    a {
      img {
        width: auto;
      }
    }
  }

  @media screen and (min-width: 768px) {
    gap: 20px;

    a {
      width: calc(100% / 3 - 20px);

      div {
        display: block;
      }

      img {
        width: auto;
      }

      label {
        margin-bottom: 6px;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    a {
      padding: 24px 40px;

      label {
        font-size: 26px;
        margin-bottom: 10px;
      }

      span {
        font-size: 18px;
      }
    }
  }
`;
