import React from "react";
import Title from "./Title";
import bg01 from "@images/mobile/about_img.jpg";
import bg02 from "@images/about_img.jpg";
import bgLogo from "@images/bg_logo.png";
import commonStyles from "@styles/common";
import { BigMobile, Tablet } from "@utils/responsive";
import { css } from "@emotion/react";
import propTypes from "prop-types";

About.propTypes = {
  menuRef: propTypes.object,
};

export default function About({ menuRef }) {
  return (
    <section css={about} id="about" ref={(el) => (menuRef.current[0] = el)}>
      <div css={inner}>
        <Title title="ABOUT" subTitle="Combines Blockchain and AI" />
        <div css={items}>
          <BigMobile>
            <p>
              The AGC combines blockchain technology with artificial
              intelligence technology.
              <br />
              <br />
              AIDAPPS Group operates various platforms and subsidiaries,
              focusing on software development businesses (SaaS) by combining
              decentralized distributed cloud-based artificial intelligence
              technology and blockchain technology.
              <br />
              <br />
              By maximizing the synergy effect through the convergence of
              blockchain and AI technology, we are laying the groundwork for
              growth as a global AI blockchain company.
            </p>

            <img src={bg01} alt="Combines Blockchain and AI" className="bg" />
          </BigMobile>
          <Tablet>
            <p>
              The AGC combines blockchain technology with artificial
              intelligence technology.
              <br />
              <br />
              AIDAPPS Group operates various platforms and subsidiaries,
              focusing on software development businesses (SaaS) by combining
              decentralized distributed cloud-based artificial intelligence
              technology and blockchain technology.
              <br />
              <br />
              By maximizing the synergy effect through the convergence of
              blockchain and AI technology, we are laying the groundwork for
              growth as a global AI blockchain company.
            </p>
            <img src={bg02} alt="Combines Blockchain and AI" />
          </Tablet>
        </div>
        <img src={bgLogo} alt="logo" className="bgLogo" />
      </div>
    </section>
  );
}

const { colors, font } = commonStyles;

const about = css`
  padding: 45px 20px 0;
  background-color: ${colors.lightestBlue};
  position: relative;

  ::before {
    content: "";
    width: 100%;
    height: 45px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${colors.white};
  }

  @media screen and (min-width: 768px) {
    ::before {
      height: 80px;
    }
  }

  @media screen and (min-width: 1024px) {
    padding: 130px 20px 0;
  }
`;

const inner = css`
  width: min(100%, 1200px);
  margin: 0 auto;
  position: relative;

  p {
    font-size: 18px;
    font-weight: ${font.weight.light};
    line-height: 1.3;
    text-align: center;
    margin-bottom: 35px;
  }

  img.bg {
    position: relative;
    margin: 0 auto;
  }

  img.bgLogo {
    position: absolute;
    bottom: -37px;
    left: 0;
  }

  @media screen and (max-width: 767px) {
    img.bgLogo {
      width: 190px;
      bottom: -59px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @media screen and (min-width: 768px) {
    p {
      text-align: left;
      margin: 0 40px 0 0;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    img.bgLogo {
      width: 300px;
      bottom: -14px;
    }
  }

  @media screen and (min-width: 1024px) {
    display: flex;

    p {
      text-align: left;
      margin: 0 120px 0 60px;
      line-height: 1.6;
    }
  }
`;

const items = css`
  @media screen and (min-width: 768px) {
    display: flex;
  }
`;
