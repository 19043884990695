import React from "react";
import img01 from "@images/news/img01.jpeg";
import img02 from "@images/news/img02.jpeg";
import img03 from "@images/news/img03.jpeg";
import img04 from "@images/news/img04.jpeg";
import img05 from "@images/news/img05.jpeg";
import img06 from "@images/news/img06.jpeg";
import img07 from "@images/news/img07.jpeg";
import img08 from "@images/news/img08.jpeg";
import img09 from "@images/news/img09.jpeg";
import img10 from "@images/news/img10.jpeg";
import img11 from "@images/news/img11.jpeg";
import img12 from "@images/news/img12.jpeg";
import img13 from "@images/news/img13.jpeg";
import img14 from "@images/news/img14.jpeg";
import img15 from "@images/news/img15.jpeg";
import img16 from "@images/news/img16.jpeg";
import img17 from "@images/news/img17.jpeg";
import img18 from "@images/news/img18.jpeg";
import img19 from "@images/news/img19.jpeg";
import img20 from "@images/news/img20.jpeg";
import img21 from "@images/news/img21.jpeg";
import img22 from "@images/news/img22.jpeg";
import img23 from "@images/news/img23.jpeg";
import img24 from "@images/news/img24.jpeg";
import img25 from "@images/news/img25.jpeg";
import img26 from "@images/news/img26.jpeg";
import img27 from "@images/news/img27.jpeg";
import img28 from "@images/news/img28.jpeg";
import img29 from "@images/news/img29.jpeg";
import img30 from "@images/news/img30.png";
// 여기서 부터 새로 추가 합니다. 230425
import img31 from "@images/news/img31.jpg";
import img32 from "@images/news/img32.jpg";
import img33 from "@images/news/img33.jpg";
import img34 from "@images/news/img34.jpg";
import img35 from "@images/news/img35.jpg";
import img36 from "@images/news/img36.jpg";
import img37 from "@images/news/img37.jpg";

import arrowImg from "@images/news/more_arrow.png";
import { Mobile } from "@utils/responsive";
import commonStyles from "@styles/common";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { css } from "@emotion/react";
import { MdAdd } from "react-icons/md";
import { useMediaQuery } from "react-responsive";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

export default function Carousel() {
  const responsive = useMediaQuery({ minWidth: 426 });
  return (
    <Slider {...setting} css={slider}>
      {items
        .map((item) => (
          <a
            css={slideItem}
            id={item.id}
            href={item.href}
            target="_blank"
            rel="noreferrer noopenner"
            key={item.id}
          >
            <span className="date">{item.date}</span>
            <label className="title">{item.title}</label>
            <div className="img">
              <img src={item.imgSrc} alt={item.title} />
            </div>
            <p className="desc">{item.desc}</p>
            <span className="more">
              More
              {responsive && <img src={arrowImg} alt="화살표" />}
              <Mobile>
                <MdAdd />
              </Mobile>
            </span>
          </a>
        ))
        .reverse()}
    </Slider>
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <button css={arrow} type="button" onClick={onClick} className="prev">
      <MdChevronLeft />
    </button>
  );
}

function NextArrow(props) {
  const { onClick } = props;
  return (
    <button css={arrow} type="button" onClick={onClick} className="next">
      <MdChevronRight />
    </button>
  );
}

const setting = {
  dots: false,
  arrows: true,
  autoplay: true,
  infinite: true,
  slidesToShow: 3,
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const { colors, font } = commonStyles;

const slider = css`
  display: flex;
  justify-content: space-between;

  .slick-slide {
    & > div {
      margin-right: 30px;
    }
  }

  @media screen and (max-width: 700px) {
    .slick-slide {
      & > div {
        margin: 0 20px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .slick-slide {
      & > div {
        margin: 0 20px;
      }
    }
  }
`;

const slideItem = css`
  background-color: ${colors.darkWhite};
  border-radius: 15px;
  padding: 40px 20px;

  .date {
    color: ${colors.black};
    opacity: 0.5;
    display: block;
    margin-bottom: 10px;
  }

  .title {
    font-size: 20px;
    font-weight: ${font.weight.bold};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
    width: 100%;
    max-height: 100px;
    margin-bottom: 16px;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .img {
    margin-bottom: 16px;
    height: 200px;
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 auto 16px;

    img {
      height: 100%;
    }
  }

  .desc {
    font-size: 15px;
    color: ${colors.gray};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
    width: 100%;
    margin-bottom: 20px;
    max-height: 100px;
    margin-bottom: 16px;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-height: 1.6;
  }

  .more {
    color: ${colors.lightBlue};
    font-weight: ${font.weight.black};
    font-size: 20px;
  }

  @media screen and (max-width: 425px) {
    padding: 30px 20px;

    .date {
      font-size: 13px;
    }

    .title {
      font-size: 15px;
      font-weight: ${font.weight.medium};
    }

    .desc {
      font-size: 13px;
    }

    .more {
      font-size: 17px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const arrow = css`
  width: 90px;
  height: 90px;
  border: 1px solid ${colors.darkGray};
  border-radius: 50%;
  background-color: ${colors.white};
  font-size: 60px;
  display: grid;
  place-content: center;
  position: absolute;
  bottom: 100px;
  top: 50%;
  transform: translateY(-50%);
  color: ${colors.gray};
  box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.05);

  &:hover {
    color: ${colors.lightBlue};
  }

  &.prev {
    left: -140px;
  }

  &.next {
    right: -110px;
  }

  @media screen and (max-width: 1439px) {
    display: none;
  }

  @media screen and (min-width: 1440px) and (max-width: 1540px) {
    &.prev {
      transform: translate(40px, -50%);
    }

    &.next {
      transform: translate(-40px, -50%);
    }
  }
`;

const items = [
  {
    id: 0,
    href: "http://www.it-b.co.kr/news/articleView.html?idxno=41584",
    date: "2020.06.23",
    title: `에이아이댑스, AI분산클라우드 신기술 시연회 열어`,
    imgSrc: img01,
    desc: `AI 블록체인 기반의 분산형 클라우드 솔루션 기업 ‘에이아이댑스’가 국내외 투자자를 모시고 D-cloud 데모버전을 공개하는 ‘2020 AI블록체인 클라우드 기술 시연회’를 개최했다고 23일 밝혔다.`,
  },
  {
    id: 1,
    href: "http://www.fntoday.co.kr/news/articleView.html?idxno=221962",
    date: "2020.06.26",
    title: `에이콘 프로젝트, “분산형 클라우드 플랫폼 생태계 활성화 기대”`,
    imgSrc: img02,
    desc: `AICON 프로젝트는 AI Blockchain을 기반으로 분산 클라우드 하드웨어, 소프트웨어(마이닝풀)의 컴퓨팅자원과 PC나 스마트폰의 유휴자원을 AI분석이 필요한 개인과 기업에게 제공하는 플랫폼이다. AICON 생태계 참여자는 데이터 제공자, AICON 플랫폼, 리소스 제공자로 구성된다.`,
  },
  {
    id: 2,
    href: "http://www.segyebiz.com/newsView/20200709514637?OutUrl=naver",
    date: "2020.07.09",
    title: `에이아이댑스, 에이콘 프로젝트 투자설명회 개최`,
    imgSrc: img03,
    desc: `블록체인 기반의 분산클라우드 기업 에이아이댑스가 지난 3일 에이콘 프로젝트 투자설명회를 개최했다고 9일 밝혔다. 에이아이댑스는 지난달 9일 라마다 호텔에서 열린 데모설명회에 참석한 투자자들을 포함해 벤처캐피털(VC) 관계자 및 엑셀러레이터들이 참석한 이번 투자 설명회에서 에이콘 프로젝의 올해 하반기 기술개발 및 상용화 계획과 거래소 추가상장 계획을 발표했다.`,
  },
  {
    id: 3,
    href: "http://www.it-b.co.kr/news/articleView.html?idxno=42409",
    date: "2020.07.22",
    title: `에이콘 프로젝트, 오는 8월 25일 메인넷 런칭`,
    imgSrc: img04,
    desc: `에이콘은 오는 8월 25일 자체 블록체인 메인넷 ‘에이콘 블록체인’ 런칭을 앞두고 있다. 에이콘 프로젝트는 2020년 5월 이더리움(ERC-20) 기반으로 암호화폐를 발행하였고, 인공지능 블록체인 기반으로 분산 클라우드 환경 구현을 위한 자체 메인넷 테스트를 마친 상태이다.`,
  },
  {
    id: 4,
    href: "http://www.fntoday.co.kr/news/articleView.html?idxno=225511",
    date: "2020.07.23",
    title: `에이콘 코인, 글로벌 암호화폐 거래소 ‘프로비트’ 7월 30일 상장`,
    imgSrc: img05,
    desc: `오는 7월 30일, 글로벌 암호화폐 거래소 프로비트(Probit)에 블록체인기반의 분산 클라우드 생태계를 구축한 에이콘 프로젝트의 AICO 코인이 상장 소식을 전하며 주목받고 있다.`,
  },
  {
    id: 5,
    href: "https://n.news.naver.com/mnews/article/029/0002613573?sid=105",
    date: "2020.07.27",
    title: `AI 블록체인 프로젝트 에이콘(AICON), 7월 30일 프로비트 상장`,
    imgSrc: img06,
    desc: `유휴자원을 사용하여 AI와 블록체인의 데이터 활용을 극대화하는 에이콘(AICON)프로젝트의 AICO 코인이 7월 30일 글로벌 거래소 프로비트에 상장한다.`,
  },
  {
    id: 6,
    href: "http://www.it-b.co.kr/news/articleView.html?idxno=42705",
    date: "2020.08.03",
    title: `에이콘(AICO) 월렛, 9월 중순경 런칭 예정`,
    imgSrc: img07,
    desc: `에이콘 프로젝트에서 개발중인 AICO월렛(가제)이 9월 중순경 런칭될 예정이다. 새롭게 출시될 AICO월렛은 에이콘(AICO)은 물론, 다수의 메이저 메인넷 코인 및 ERC-20 토큰을 지원한다. 기본적인 암호화폐 보관, 전송, 수신 기능 이외에도 D-클라우드 리워드 기능이 포함될 계획이다. 또한 10월 중에 암호화폐 스테이킹 기능과 에이콘 사용자만을 위한 실사용 컨텐츠들도 업데이트될 예정에 있다.`,
  },
  {
    id: 7,
    href: "http://www.fntoday.co.kr/news/articleView.html?idxno=230667",
    date: "2020.09.02",
    title: `에이콘 D-클라우드 하드웨어 제품 AICO-D1 출시`,
    imgSrc: img08,
    desc: `에이콘 D-클라우드 하드웨어 제품(AICO-D1)이 오는 15일에 출시된다. 에이콘 D-클라우드 하드웨어는 블록체인 기반의 분산환경에서 대용량 데이터를 저장, 관리, 처리할 수 있으며, 보안은 강화하고 데이터 처리비용은 절감할 수 있게 해준다.`,
  },
  {
    id: 8,
    href: "https://www.dailian.co.kr/news/view/921648/?sc=Naver",
    date: "2020.09.22",
    title: `에이콘 D-클라우드 하드웨어, 사전판매 500대 3일 만에 완판`,
    imgSrc: img09,
    desc: `에이콘 프로젝트의 D-Cloud 하드웨어(AICO-D1) 500대가 사전판매 3일 만에 완판을 기록했다. 분산 환경에서 대용량 데이터를 저장, 관리, 처리할 수 있도록 만들어진 D-Cloud는 지난 9월 1일부터 당초 2주간 진행 예정이었으나 큰 관심 속에서 판매 3일만에 완료되었다고 밝혔다.`,
  },
  {
    id: 9,
    href: "https://www.aitimes.kr/news/articleView.html?idxno=18165",
    date: "2020.10.25",
    title: `에이아이댑스, '국제인공지능대전 2020'에서 인공지능 분산클라우드 플랫폼 선보인다`,
    imgSrc: img10,
    desc: `에이아이댑스(대표 허태인)은 27일부터 29일까지 서울 삼성동 코엑스에서 열리는 ‘국제인공지능대전(AI EXPO KOREA 2020)'에서 인공지능(AI) 분산클라우드 플랫폼을 선보인다. 이 플랫폼은 에이아이댑스(AI-Dapps)의 AI Blockchain을 기반으로 하드웨어와 소프트웨어의 유휴 컴퓨팅자원을 수요자에게 제공하는 시스템이다.`,
  },
  {
    id: 10,
    href: "https://www.dailysecu.com/news/articleView.html?idxno=115665",
    date: "2020.10.28",
    title: `인공지능 분산 클라우드 기업 ‘에이아이댑스’, 2020 국제인공지능대전서 기업형 모델 선보여`,
    imgSrc: img11,
    desc: `인공지능 분산 클라우드 전문 기업인 에이아이댑스는 코엑스에서 27일 열린 ‘2020 국제인공지능대전(AI EXPO KOREA)에 일반형 모델 AICO-D2, 기업형 모델 AICO-E1, 채굴형 모델 AICO-M1을 전시했다. 일반형 모델 AICO-D2는 문서, 자연어처리, AI 딥러닝에 사용된다. 또한 파일 공유 및 문서보안 플랫폼 분산환경에 사용되며, 분산형 클라우드 서버사업에 이용된다.`,
  },
  {
    id: 11,
    href: "http://www.newsrep.co.kr/news/articleView.html?idxno=151817",
    date: "2020.10.28",
    title: `에이아이댑스, AI EXPO KOREA 참가… 분산 클라우드 기업형, 채굴형 모델 첫선`,
    imgSrc: img12,
    desc: `인공지능 분산 클라우드 전문 기업인 에이아이댑스는 코엑스에서 27일 열린 ‘2020 국제인공지능대전(AI EXPO KOREA)에 일반형 모델 AICO-D2, 기업형 모델 AICO-E1, 채굴형 모델 AICO-M1을 전시했다. 일반형 모델 AICO-D2는 문서, 자연어처리, AI 딥러닝에 사용된다. 또한 파일 공유 및 문서보안 플랫폼 분산환경에 사용되며, 분산형 클라우드 서버사업에 이용된다.`,
  },
  {
    id: 12,
    href: "https://www.seoul.co.kr/news/newsView.php?id=20210108500160&wlog_tag3=naver",
    date: "2021.01.08",
    title: `에이아이댑스, 전국 각지에 분산형 클라우드 구축`,
    imgSrc: img13,
    desc: `코로나19 유행으로 비대면 서비스, 온라인 수업, 비디오 스트리밍 이용 등이 폭발적으로 늘어남에 따라 데이터 사용량도 급증했다는 연구가 나왔다. 세계 데이터 트래픽은 평균적으로 20~100% 증가했으며, 기업들의 클라우드 기술 도입이 급속도로 확산되고 있다. 사내에 자체 프라이빗 클라우드 인프라를 구축하고, 동시에 AWS를 비롯해 구글 클라우드 플랫폼 또는 마이크로소프트 Azure와 같은 퍼블릭 클라우드를 사용하면서 하이브리드 환경을 구축하고 있다.`,
  },
  {
    id: 13,
    href: "http://www.busaneconomy.com/news/articleView.html?idxno=227144",
    date: "2021.01.08",
    title: `에이콘 암호화폐 채굴형 하드웨어 AICO-M1, 최근 수요량 급증`,
    imgSrc: img14,
    desc: `‘암호화폐 대장’ 비트코인이 3만달러 천장을 뚫으면서 새해 문을 열었다. 투자자들은 발빠르게 비트코인 채굴기와 암호화폐 채굴관련 종목 매수에 나섰다. 비트메인의 채굴기 ASIC은 가격이 두배 가까이 올랐음에도 불구하고 물량이 8월까지 선판매됐다. 암호화폐 채굴기 시장의 수요가 줄어들 기미가 보이지 않고 있으며, 거래량이 급증하고 있다.`,
  },
  {
    id: 14,
    href: "https://news.mt.co.kr/mtview.php?no=2021021611042150035",
    date: "2021.02.16",
    title: `에이콘(AICON), 분산 클라우드 제공 플랫폼`,
    imgSrc: img15,
    desc: `AI 블록체인을 기반으로 딥러닝, 머신러닝 등 AI 연산이 필요한 개인과 기업에게 필요한 컴퓨팅 자원을 horovod 기반의 슈퍼컴퓨터를 통해 참여한 참여자들의 유휴 컴퓨팅 자원을 네트워크상에서 제공받고 토큰으로 보상을 받을 수 있는 분산형 시스템을 기본으로 한다.`,
  },
  {
    id: 15,
    href: "https://www.fnnews.com/news/202102251053113234",
    date: "2021.02.25",
    title: `에이콘(AICON) 프로젝트, ‘에이콘 월렛’ 업데이트 실시`,
    imgSrc: img16,
    desc: `에이콘(AICON) 프로젝트는 자사가 서비스하는 모바일 애플리케이션 ‘에이콘 월렛’에서 파일코인 연동 업데이트를 3월 3일(수요일)에 진행한다고 밝혔다. 모바일 애플리케이션 ‘에이콘 월렛’은 암호화폐 전송 기능이 탑재되고, D-Cloud 하드웨어의 컴퓨팅자원 제공 보상량 확인 기능을 갖추고 있다. 이용자는 D-Cloud의 CPU, GPU, Memory, Storage 등 유휴자원을 AICON Platform에 제공하고 에이콘 월렛으로 AICON Token을 보상받게 된다. 보상받은 에이콘은 프로비트 거래소 또는 에이프로빗 거래소로 전송하여 이용이 가능하다.`,
  },
  {
    id: 16,
    href: "https://view.asiae.co.kr/article/2021031810281356034",
    date: "2021.03.18",
    title: `에이아이댑스, 과학기술정보통신부 인가 기업부설연구소 설립`,
    imgSrc: img17,
    desc: `AI 블록체인 클라우드 서비스를 전문으로 하는 에이아이댑스가 자체 기업부설연구소를 설립하고 클라우드 기술 연구에 속도를 낸다고 밝혔다. 기업부설연구소를 인정받기 위해서는 독립된 연구공간과 시설, 기자재 등 물질적 요건과 일정 수준 이상의 연구전담요원 등의 인적 요건이 갖춰져야 한다. 에이아이댑스는 이에 충족한 물적, 인적 요건으로 기업부설연구소를 인정받았다.`,
  },
  {
    id: 17,
    href: "https://www.sedaily.com/NewsView/22KZ8Y4HMF",
    date: "2021.04.06",
    title: `에이아이댑스, 2021 AI EXPO(국제인공지능대전) 성황리 마쳐`,
    imgSrc: img18,
    desc: `분산 클라우드 기반의 서비스 플랫폼 전문업체인 에이아이댑스(대표 허태인)는 지난 3월 24일부터 26일까지 3일간 서울 코엑스에서 진행된 국제인공지능대전(2021 AI EXPO KOREA)에 참가해 분산 클라우드 환경에서 빅데이터에 대한 분석을 수행하는 서비스 플랫폼인 DCS(DCloud Contents Service, 이하 DCS) v1.0 솔루션을 선보였다.`,
  },
  {
    id: 18,
    href: "https://news.mt.co.kr/mtview.php?no=2021040910404926050&type=1",
    date: "2021.04.09",
    title: `에이아이댑스 AICON, 분산형 클라우드 플랫폼..AI 블록체인시장 공략`,
    imgSrc: img19,
    desc: `에이아이댑스(대표 허태인)는 AI 블록체인 기반 플랫폼 ‘에이콘(AICON)’을 선보여 업계의 주목을 받고 있다. 에이콘(AICON) 프로젝트는 Horovod 기반의 슈퍼컴퓨터를 통해 Node들의 CPU, GPU, MEMORY, STORAGE 등 유휴자원을 네트워크상에서 빌리고, 보상으로 AICON Token을 지급하는 분산형 시스템을 기본으로 한다.`,
  },
  {
    id: 19,
    href: "https://www.sedaily.com/NewsView/22NMTZ4IB7",
    date: "2021.06.14",
    title: `에이아이댑스, 'DCS v1.0' GS인증 1등급 획득`,
    imgSrc: img20,
    desc: `AI 클라우드 전문 기업 에이아이댑스는 자사 빅데이터 분석 플랫폼 ‘DCS v1.0’이 한국정보통신기술협회(TTA)로부터 GS인증 1등급을 획득했다고 14일 밝혔다. GS인증은 국제표준화기구(ISO) 표준 인증을 기반으로 일련의 테스트 과정을 거쳐, 일정 수준의 품질을 갖춘 소프트웨어에 대해 국가가 부여하는 인증제도다. 인증을 획득한 제품은 정부ㆍ공공기관 사업에서 우선 구매 대상으로 지정된다.`,
  },
  {
    id: 20,
    href: "https://www.sedaily.com/NewsView/22QE1KLZXK",
    date: "2021.08.30",
    title: `㈜에이아이댑스, 혁신성장유형 기업으로 인정받아 벤처기업확인서 획득img`,
    imgSrc: img21,
    desc: `㈜에이아이댑스는 벤처기업확인제도 심사를 통해 기술혁신성 및 사업 성장성을 인정받아 혁신성장유형으로서의 벤처기업 인증을 획득했다. 벤처기업 인증은 ‘벤처기업 육성에 관한 특별 조치법’에 의해 최종 승인된 중소기업 대해 인증해주는 기업인증제도를 일컫는데, 인증받기 위해서는 기업의 성과 외에도 기반 및 활동을 반영하여 미래 성장 가능성이 우수한 중소 기업에게 벤처기업 인증을 부여한다.`,
  },
  {
    id: 21,
    href: "https://www.dailysecu.com/news/articleView.html?idxno=134475",
    date: "2022.02.23",
    title: `에이아이댑스, ‘2022년 기술평가 우수기업 인증서’ T-4등급 획득`,
    imgSrc: img22,
    desc: `인공지능(AI) 분산 클라우드 전문 기업 ㈜에이아이댑스(대표 허태인)는 최근 국가공인 기술신용평가기관 NICE평가정보㈜에서 실시한 기술신용평가(TCB)에서 상위 등급인 ‘T-4’ 등급을 획득해 우수 기술력 기업으로 공식 인증받았다고 밝혔다.`,
  },
  {
    id: 22,
    href: "http://www.it-b.co.kr/news/articleView.html?idxno=58141",
    date: "2022.03.23",
    title: `에이아이댑스, 블록체인 클라우드 서비스 관련 특허 8건 등록 완료`,
    imgSrc: img23,
    desc: `인공지능(AI) 분산 클라우드 전문 기업 에이아이댑스는 블록체인 클라우드 서비스 관련 특허 8건 등록을 완료했다고 23일 밝혔다. 에이아이댑스는 ‘블록체인 기반 클라우드 서비스 제공 방법 및 시스템’, ‘블록체인 기반의 IaaS·PaaS·SaaS를 포함하는 클라우드 서비스 제공 방법’, ‘머신러닝 서비스를 포함하는 클라우드 서비스 제공 방법 및 시스템’, ‘블록체인 기반으로 검증된 노드와 비검증된 노드를 이용한 서비스 제공 방법 및 시스템’, ‘마스터 노드를 이용한 블록체인 기반 클라우드 서비스 제공 방법 및 시스템’, ‘블록체인 기반으로 머신러닝에 따른 보상을 제공하는 클라우드 서비스 제공 방법 및 시스템’ 총 8개의 특허 등록을 완료했다. 또한 추가로 현재 2건의 특허가 출원되어 심사 중이라고 발표했다.`,
  },
  {
    id: 23,
    href: "http://www.businesskorea.co.kr/news/articleView.html?idxno=91605",
    date: "2022.04.27",
    title: `에이아이댑스-컴포트앤커스텀, P2P 크라우드펀딩 플랫폼 개발 MOU`,
    imgSrc: img24,
    desc: `㈜에이아이댑스는 지난 11일, 에이아이댑스 본사에서 파트너사인 ㈜컴포트앤커스텀과 중소형 호텔 리모델링 상품을 전문적으로 펀딩하는 ‘P2P 크라우드펀딩 사업’을 공동 추진 업무협약(MOU)을 체결했다고 밝혔다.`,
  },
  {
    id: 24,
    href: "http://www.it-b.co.kr/news/articleView.html?idxno=60118",
    date: "2022.06.07",
    title: `위클립스, 에이아이댑스와 메타버스 인공지능 서비스 개발 업무협약 체결`,
    imgSrc: img25,
    desc: `위클립스는 에이아이댑스(AI-Dapps)와 인공지능 기술개발을 위해 업무협약(MOU)을 체결했다고 7일 밝혔다. 위클립스는 경제분야 소셜 메타버스 플랫폼 딜링룸(Dealing room) 운영사로 이번 에이아이댑스와 인공지능 개발 업무협약은 경제 콘텐츠를 바탕으로 한 메타버스 생태계 구축에 더욱 박차를 가하는 계기가 될 것이라고 말했다.  에이아이댑스는 블록체인 클라우드 전문기업으로 NICE 평가정보에서 실시한 기술신용평가에서 ‘시스템 관리 및 보안 소프트웨어 개발’로 우수한 전문성을 인정받은 기업이다.`,
  },
  {
    id: 25,
    href: "https://www.dailysecu.com/news/articleView.html?idxno=137977",
    date: "2022.07.04",
    title: `메타샌-에이아이댑스, 신소재 사업과 AI 분석기술 및 블록체인 인공지능 기술관련 MOU체결`,
    imgSrc: img26,
    desc: `주식회사 메타샌은 지난 20일, 서울시 강남구 대치동에 위치한 ㈜메타샌 본사에서 파트너사인 에이아이댑스와 신소재 사업과 AI 분석기술 및 블록체인 인공지능 기술관련 업무협약(MOU)을 체결했다고 밝혔다. 메타샌은 청정한 생태계(BLUE ECOLOGY)를 추구하며, SAN(Silvix Antimicrobial Nanocomposite) 신소재를 인간의 주거환경(또는 생물들의 서식지), 식품, 생활용품 등 다양한 산업에서 적용하도록 지원하고 있다.`,
  },
  {
    id: 26,
    href: "http://www.nextdaily.co.kr/news/articleView.html?idxno=211532",
    date: "2022.11.03",
    title: `㈜에이아이댑스, 부동산 투자자 위한 부동산 NFT 간접투자 플랫폼 '메타글로브' 개발`,
    imgSrc: img27,
    desc: `㈜에이아이댑스는 ㈜컴포트앤커스텀과 공동 출자해 설립한 ㈜준앤프렌즈에서 부동산 NFT 간접투자 플랫폼인 ‘메타글로브(Metaglobe)’를 11월 중에 런칭할 예정이라고 밝혔다. 메타글로브는 실물 부동산에 직접 투자하지 않고, 부동산 운용 특수목적법인(SPC)의 주식을 NFT화해 해당 부동산의 주식지분을 획득하고, 투자한 지분만큼의 운영수익 및 매매차익을 배당하며, 해당 투자지분 및 NFT를 자유롭게 매매할 수 있도록 하는 부동산 NFT 투자 플랫폼이다. 에이아이댑스는 메타글로브 플랫폼에 대한 전체 기획과 개발 등 기술적인 부분을 전담했다.`,
  },
  {
    id: 27,
    href: "https://www.dailysecu.com/news/articleView.html?idxno=141103",
    date: "2022.11.09",
    title: `에이아이댑스, 반려동물 헬스케어 위한 스마트 배변판 제품 개발 나서`,
    imgSrc: img28,
    desc: `반려동물 헬스케어 사업을 위해서 ㈜에이아이댑스가 출자해 설립한 ㈜펫케어랩에서 반려견이 사용하는 스마트 배변판 제품 개발을 진행 중이라고 밝혔다. 개발 중인 스마트 배변판은 기존 소변 분석기의 기능을 탑재 시키고 인공지능(AI) 기반의 분석 기술을 활용하여 반려동물의 질병을 조기에 발견하고 예방을 통해서 건강한 펫(Pet)가족의 삶을 영위하는 것을 목표로 하는 반려동물 헬스케어 제품이다.`,
  },
  {
    id: 28,
    href: "https://www.dailysecu.com/news/articleView.html?idxno=141303",
    date: "2022.11.16",
    title: `에이아이댑스, IPFS 기반의 탈중앙화된 분산기술 이용한 새로운 장을 열 포부 밝혀`,
    imgSrc: img29,
    desc: `에이아이댑스는 분산형 파일 시스템(Inter Planetary File System, IPFS) 기반의 탈중앙화된 분산기술을 이용한 새로운 장을 열 포부를 밝혔다. IPFS는 데이터의 해시값을 이용해 분산되어 저장되어 있는 노드에서 데이터를 찾고, 이 데이터를 조각 낸 후 빠른 속도로 가져온 뒤 하나로 합쳐서 보여주는 방식으로 작동한다. 파일 조각을 여러 노드에서 동시에 가져오기 때문에 기존 대역폭 비용을 60% 이상 절감할 수 있으며, 전 세계 수많은 분산된 노드들이 해당 정보들을 저장하기 때문에 기존 HTTP 방식에 비해 훨씬 빠른 속도로 데이터를 저장하고 가져올 수 있고 데이터 유실을 방지할 수 있는 차세대 기술로 대두되고 있다.`,
  },
  {
    id: 29,
    href: "https://www.sisunnews.co.kr/news/articleView.html?idxno=174594",
    date: "2022.12.02",
    title: `㈜에이아이댑스, 실시간 도로 위험감지 플랫폼 ‘디박스(DeBox)’ 개발`,
    imgSrc: img30,
    desc: `분산 클라우드(Decentralized Cloud) 기반 플랫폼 전문 개발 기업 에이아이댑스는 자동차 운전자를 위해 실시간으로 도로의 위험상황을 감지하는 플랫폼인 ‘디박스(DeBox)’에 대한 기획을 마무리하고 개발에 들어간다고 밝혔다.`,
  },
  // 여기서 부터 news 추가합니다 230425
  {
    id: 30,
    href: "http://www.kdpress.co.kr/news/articleView.html?idxno=117972",
    date: "2023.02.08",
    title: `㈜에이아이댑스, ‘2023년 비전 선포식’ 행사 성료`,
    imgSrc: img31,
    desc: `인공지능 분산 클라우드(Decentralized Cloud)기반 플랫폼 전문 개발 기업인 에이아이댑스는 2023년 비전 선포식 행사를 성황리에 마무리했다고 밝혔다.`,
  },
  {
    id: 31,
    href: "https://www.dailysecu.com/news/articleView.html?idxno=145146",
    date: "2023.04.13",
    title: `에이아이댑스, 미국 시애틀 UniLAB Hub와 AI 플랫폼 사업 협력을 위한 전략적 파트너쉽 체결`,
    imgSrc: img32,
    desc: `주식회사 에이아이댑스는 지난 3월 30일(현지시간) 미국 시애틀에 본사를 두고 있는 UniLAB HUB와 미국시장 진출 및 마케팅 협력 관계 구축을 위한 파트너쉽을 체결하고 전략적 협력을 강화하기로 했다고 밝혔다.`,
  },
  {
    id: 32,
    href: "https://www.dailysecu.com/news/articleView.html?idxno=145321",
    date: "2023.04.20",
    title: `에이아이댑스, 미국 Moonbeam과 전략적 파트너십 체결`,
    imgSrc: img33,
    desc: `주식회사 에이아이댑스는 지난 28일(현지시간) 미국 시애틀에서 인공지능 전문 기업 Moonbeam과 파트너십을 체결했다고 밝혔다.`,
  },
  {
    id: 33,
    href: "http://www.kdpress.co.kr/news/articleView.html?idxno=119540",
    date: "2023.04.28",
    title: `㈜에이아이댑스, Seattle Basecamp Inc와 미국 시장 진출 및 투자유치 MOA 체결`,
    imgSrc: img34,
    desc: `주식회사 에이아이댑스는 지난 29일(현지시간) 미국 시애틀에서 Seattle Basecamp Inc와 미국 시장 진출 및 투자유치를 위한 MOA를 체결했다고 28일 밝혔다.`,
  },
  {
    id: 34,
    href: "https://www.dailysecu.com/news/articleView.html?idxno=145755",
    date: "2023.05.04",
    title: `에이아이댑스, Global Connex와 협력 관계 구축`,
    imgSrc: img35,
    desc: `주식회사 에이아이댑스는 지난 29일(현지시간) 미국 시애틀에서 Global Connex와 미국 시장 진출과 관련하여 지원협력 체계를 마련한다고 밝혔다.`,
  },
  {
    id: 36,
    href: "http://www.kdpress.co.kr/news/articleView.html?idxno=120991",
    date: "2023.07.06",
    title: `㈜에이아이댑스, AGC몰 리뉴얼로 고객 편의성 강화`,
    imgSrc: img37,
    desc: `주식회사 에이아이댑스의 종합 커머스 플랫폼 AGC몰은 기존 상품 수 대비 2.5배 이상의 제품을 선보이며 8월 중순 새롭게 오픈한다고 6일 밝혔다.`,
  }
];
