import React from "react";
import commonStyles from "@styles/common";
import img01 from "@images/mobile/service_bn_01.png";
import img02 from "@images/mobile/service_bn_02.png";
import img03 from "@images/mobile/service_bn_03.png";
import img04 from "@images/mobile/service_bn_04.png";
import img05 from "@images/mobile/service_bn_05.png";
import img06 from "@images/mobile/service_bn_06.png";
import img07 from "@images/mobile/service_bn_07.png";
import { css } from "@emotion/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdWest, MdEast } from "react-icons/md";

export default function Carousel() {
  return (
    <Slider {...setting} css={slider}>
      <div className="item">
        <img src={img01} alt="DCS" />
        <label>
          <span>01.</span> DCS
        </label>
        <p className="desc">
          DCS v1.0 Ai-based data automated analysis and development platform
        </p>
        <p className="subDesc">
          DCS (D-Cloud Contents Service) is a service platform that supports
          data analysis and development using AI technology in a distributed
          cloud environment.
        </p>
      </div>
      <div className="item">
        <img src={img02} alt="Deplay" />
        <label>
          <span>02.</span> Deplay
        </label>
        <p className="desc">AI Data Sharing Platform (Deplay)</p>
        <p className="subDesc">
          DePlay is a platform that uploads all data that requires AI analysis
          and shares it for free discussion. It aims to classify by category so
          that users can easily find the data they want, share the analyzed
          results, conduct data analysis in connection with the DCS platform,
          and increase data utilization.
        </p>
      </div>
      <div className="item">
        <img src={img03} alt="PetCare Lab" />
        <label>
          <span>03.</span> PetCare Lab
        </label>
        <p className="desc">Pet Care Service Platform</p>
        <p className="subDesc">
          Petcarelab platform is a product aimed at healthy pet families' lives
          through early detection and prevention of pet diseases using smart pee
          pads at home without visiting vets and is developing products using
          smart pee pads and AI-based analysis technology.
        </p>
      </div>
      <div className="item">
        <img src={img04} alt="Metaglobe" />
        <label>
          <span>04.</span> Metaglobe
        </label>
        <p className="desc">Real Estate NFT Investment Platform</p>
        <p className="subDesc">
          The Metaglobe platform aims to acquire real estate stock shares by
          NFTizing stocks of a real estate management corporation, allocation of
          operating income and trading profits as much as the invested shares,
          and free trading of the investment shares and NFTs.
        </p>
      </div>
      <div className="item">
        <img src={img05} alt="P2P Exchange" />
        <label>
          <span>05.</span> P2P Exchange
        </label>
        <p className="desc">Decentralized P2P Exchange</p>
        <p className="subDesc">
          The Decentralized P2P Exchange (DEX) is a decen tralized
          cryptocurrency exchange operated by a P2P method for virtual currency
          users, which is operated by connecting buyers and sellers without a
          central operator and aims to connect directly to each other without
          intermediaries between trading parties.
        </p>
      </div>
      <div className="item">
        <img src={img06} alt="DCS" />
        <label>
          <span>06.</span> Coin Shopping mall
        </label>
        <p className="desc">Coin Shopping mall</p>
        <p className="subDesc">
          Aims to give real value to users by providing an online shopping mall
          where they can purchase products necessary for real life at the lowest
          price with coins without current use value.
        </p>
      </div>
      <div className="item">
        <img src={img07} alt="Mining" />
        <label>
          <span>07.</span> Mining
        </label>
        <p className="desc">Distributed Cloud-based mining business</p>
        <p className="subDesc">
          Distributed cloud-based mining business aims to store data and share
          data needed for AI technology through IPFS (Interplanetary File
          System) technology by distributing Filecoin mining-enabled cloud
          hardware to individuals to lay the foundation for AI technology
          throughout AIDAPPS.
        </p>
      </div>
    </Slider>
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <button css={arrow} type="button" onClick={onClick} className="prev">
      <MdWest />
    </button>
  );
}

function NextArrow(props) {
  const { onClick } = props;
  return (
    <button css={arrow} type="button" onClick={onClick} className="next">
      <MdEast />
    </button>
  );
}

const setting = {
  dots: false,
  arrows: true,
  autoplay: true,
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        dots: true,
        arrows: false,
      },
    },
    {
      breakpoint: 580,
      settings: {
        slidesToShow: 1,
        dots: true,
        arrows: false,
      },
    },
  ],
};

const { colors, font } = commonStyles;

const slider = css`
  .slick-slide {
    & > div {
      margin: 10px;
    }
  }

  .slick-dots {
    margin-top: 24px;
    position: static;

    li {
      &.slick-active {
        button {
          ::before {
            background-color: ${colors.indigo};
          }
        }
      }

      button {
        ::before {
          width: 12px;
          height: 12px;
          font-size: 12px;
          line-height: 0;
          opacity: 1;
          color: transparent;
          border: 1px solid ${colors.indigo};
          border-radius: 50%;
          background-color: ${colors.white};
        }
      }
    }
  }

  .item {
    background-color: ${colors.white};
    border-radius: 30px;
    border: 1px solid ${colors.darkGray};
    padding: 15px;

    img {
      margin: 0 auto 20px;
    }

    label {
      font-size: 23px;
      font-weight: ${font.weight.bold};

      span {
        color: ${colors.lightBlue};
        display: inline-block;
        margin-bottom: 20px;
      }
    }

    .desc {
      margin-bottom: 14px;
    }

    .subDesc {
      font-size: 14px;
      color: ${colors.lightGray};
    }
  }

  @media screen and (min-width: 1024px) {
    .item {
      padding: 30px;
      height: 500px;

      label {
        font-size: 34px;
      }

      .desc {
        font-size: 20px;
      }

      .subDesc {
        font-size: 16px;
        line-height: 1.4;
      }
    }
  }
`;

const arrow = css`
  width: 60px;
  height: 60px;
  border: 1px solid ${colors.darkGray};
  border-radius: 50%;
  background-color: ${colors.white};
  font-size: 24px;
  display: grid;
  place-content: center;
  position: absolute;
  bottom: 100px;

  &.prev {
    left: -300px;
  }

  &.next {
    left: -220px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1270px) {
    &.prev {
      transform: translateX(50px);
    }

    &.next {
      transform: translateX(50px);
    }
  }
`;
