const commonStyles = {
  colors: {
    black: "#222",
    white: "#fff",
    darkWhite: "#f4f4f4",
    lightBlue: "#94b7ff",
    lightestBlue: "#edf3ff",
    lightGreen: "#46e9d6",
    b1b1b1: "#b1b1b1",
    darkGray: "#d7d7d7",
    cacaec: "#cacaec",
    gray: "#777",
    lightGray: "#999",
    purple: "#7a64f8",
    indigo: "#090a41",
  },
  transition: "0.3s",
  font: {
    weight: {
      thin: 100,
      light: 300,
      regular: 400,
      medium: 500,
      bold: 700,
      black: 900,
    },
  },
};

export default commonStyles;
