import React, { useState } from "react";
import Visual from "./components/Visual";
import About from "./components/About";
import Bsc from "./components/Bsc";
import Business from "./components/Business/Business";
import Ecosystem from "./components/Ecosystem";
import Service from "./components/Service/Service";
import Relationships from "./components/Relationships";
import News from "./components/News/News";
import Partners from "./components/Partners";
import Community from "./components/Community";
import RoadMap from "./components/RoadMap";
import bgImg02 from "@images/bar_img_02.jpg";
import propTypes from "prop-types";
import { css } from "@emotion/react";

// 23.10.27 팝업창 추가
import Popup from "../../components/Popup";

Home.propTypes = {
  menuRef: propTypes.object,
};

export default function Home({ menuRef }) {
  const [isPopupVisible, setPopupVisible] = useState(true);

  const closePopup = () => {
    setPopupVisible(false);
  };

  return (
    <>
      {/* 23.10.27 팝업창 추가 */}
      <div>{isPopupVisible && <Popup onClose={closePopup} />}</div>
      <Visual />
      <About menuRef={menuRef} />
      <Bsc menuRef={menuRef} />
      <Business menuRef={menuRef} />
      <Ecosystem menuRef={menuRef} />
      <Service menuRef={menuRef} />
      <News menuRef={menuRef} />
      <Relationships menuRef={menuRef} />
      <Partners menuRef={menuRef} />
      <Community menuRef={menuRef} />
      <div css={img} />
      <RoadMap menuRef={menuRef} />
    </>
  );
}

const img = css`
  height: 400px;
  background: url(${bgImg02}) center top no-repeat;
  background-attachment: fixed;
  background-size: cover;

  @media screen and (max-width: 1023px) {
    height: 140px;
  }
`;
