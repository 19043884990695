import React from 'react'
import {css} from '@emotion/react'

function Popup({onClose}) {
    const content = `
안녕하세요 AGC 입니다.
다사다난 했던 2023년 올 한해를 마무리하며 감사한 마음을 전합니다. 

AGC 재단 및 임직원은 유저 여러분께서 함께해주신 덕분에 발전하는 계기가 되었고 응원해주심에 
성장할 수 있었습니다. 

2024년에는 유저 여러분의 응원에 보답하는 한해가 될 수 있도록 노력하겠습니다.

AGC의 사업별 진행사항을 공유해드립니다.

1) AGC 코인 상장
현재 기존에 계획하고 있던 BitMart는 최근 미국내 증권류토큰에 대한 규제강화등의 이슈로 상장후에도 
여러가지 제한사항이 있을것으로 판단하여 Bybit 혹은 Bitget 거래소로 상장할 예정입니다. 

기존 상장예정이던 BitMart는 2023년 12월 기준 코인마켓캡 38위이며 상장계획중인 
Bybit, Bitget의 경우는 각각 4위, 12위의 거래소 입니다. 

상장만을 목적으로 하는것이 아닌, 상장후 비즈니스 플랜을 고려하여 유저여러분에게 
도움이 될수있는 방향으로 준비하도록 하겠습니다.

2)펫케어랩
펫케어랩은 기존 11월 경 워킹목업 제품을 완성할 예정이었으나, 제조사와 협의를 통해 일부 디자인 및 
기능을 개선하여 완성시키는 방향으로 결정하여 2개월 정도 지연된 1월중으로 워킹목업 
제품이 완성될 예정입니다.

워킹목업 제품 준비와 함께 펫케어랩 하드웨어에 대한 특허결정서 또한 취득하였습니다.
*발명의 명칭:애완동물용 배변판 및 이를 포함하는 시스템
*출원번호:10-2023-0073492

또한, 워킹목업이 완성되면 AGC 유저 여러분을 대상으로 워킹목업을 공개하는 프라이빗한 쇼케이스 
행사 또한 진행될 예정입니다. 관심있게 지켜주시기 바랍니다.

3) AGC 플랫폼 토큰 에어드랍
플랫폼 토큰 에어드랍은 이전에 공지되었던 일정에서 변경되어 아래와 같이 에어드랍될 예정입니다.

기존:
-메타글로브(Metaglobe), 디플레이(Deplay) 12월 중
-펫케어랩(Petcarelab), 디박스(Debox) 2024년 1월 중

변경:
-메타글로브(Metaglobe), 디플레이(Deplay) 1월 15일 이전
-펫케어랩(Petcarelab), 디박스(Debox) 2024년 2월 중

에어드랍 토큰에 대한 상장은 AGC의 추가상장이 진행된 이후에 이뤄질 예정입니다. 

4)미국 법인 설립
미국 법인 설립은 펫케어랩 워킹목업(시제품)이 완성된후 진행될 예정입니다. 

미국법인의 설립을 위해 미국 법인 주주의 확정이 필요한 상황이기때문에 시제품 완성 전에 결정하기보다, 
펫케어랩 시제품이 완성 된 후 해당 시제품을 통하여 우수한 파트너사와 논의를 진행해 
미국법인 설립을 진행하기로 결정하였습니다.

유저 여러분께서 많은 관심과 성원을 보내주고 계신만큼 좋은 결과를 낼수 있도록 노력하겠습니다.

5)두바이 마이닝 시티
현재 유저여러분께서 알고계신것과 같이 MJ Asset Investment 및 왕가가 UAE의 채굴장 부지와 저렴한 
전기공급에 대한 인프라를 준비해둔 상황입니다.

또한 해당 채굴부지에 입점하기 위해 한국기업 뿐아닌, 해외의 채굴관련 기업들도 계속해서 오퍼가 들어오고 
있는 상황입니다. 

그러나, 최근 넉달간 비트코인 가격이 2배정도 상승하면서 BTC 채굴기에 대한 수요가 급격히 증가하여 
채굴기 공급에 어려움을 겪고 있습니다. 

현재 원활한 채굴기 공급라인 구축을 위해 우수한 여러 공급업체와 활발히 논의중에 있습니다. 

채굴기 공급라인에 대한 안정화만 이뤄진다면 기존에 준비해둔 대로 두바이 마이닝 사업이 
본격적으로 진행될 예정입니다. 

6) 2024년 사업계획 및 로드맵 발표 예정
2024년 사업계획과 로드맵에 대한 발표를 별도의 모임공간에서 진행할예정입니다. 

에이아이댑스의 사업 진행 방향과 로드맵에 대한 발표로 이뤄질 예정이며, 향후 일정은 1월 둘째주 내로 
확정지어 AGC 홍보채널(텔레그램, 트위터, 블로그)를 통해 공지드릴 예정입니다.
    `;
    return (
        <div className="popup">
            <div className="popup-content" css={popup}>
                <h3>[AGC - 사업별 진행사항 및 향후 계획 공지]</h3>
                <pre dangerouslySetInnerHTML={{__html: content}}/>
                <button onClick={onClose}>닫기</button>
            </div>
        </div>
    )
}

const popup = css`
    position: absolute;
    top: 50px;
    left: 50px;
    z-index: 99999999;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    color: #222;
    width: 700px;
    padding: 80px;

    h3 {
        font-size: 20px;
        margin: 0 0 30px 0;
        text-align: center;
    }

    p {
        font-size: 16px;
        line-height: 1.5;
        word-break: break-word;
    }

    pre {
        font-size: 16px;
        line-height: 1.5;
        word-break: normal;
        white-space: pre-wrap;
    }

    button {
        position: absolute;
        bottom: 30px;
        right: 30px;
        margin: 50px 0 0 0;
        padding: 10px 15px;
        background: #666;
        color: #fff;
        font-size: 15px;
        font-weight: 500;
        border: none;
        outline: none;
        cursor: pointer;
    }

    @media screen and (max-width: 767px) {
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 99999999;
        background: #fff;
        border: 1px solid #ddd;
        border-radius: 10px;
        color: #222;
        width: calc(100% - 40px);
        padding: 20px 20px 80px 20px;

        h3 {
            font-size: 15px;
            margin: 0 0 30px 0;
            text-align: center;
            line-height: 1.5;
        }

        p {
            font-size: 12px;
            line-height: 1.5;
            word-break: keep-all;
        }

        pre {
            font-size: 16px;
            line-height: 1.5;
            word-break: normal;
            white-space: pre-wrap;
        }

        button {
            position: absolute;
            bottom: 20px;
            right: 20px;
            margin: 50px 0 0 0;
            padding: 8px 15px;
            background: #666;
            color: #fff;
            font-size: 13px;
            font-weight: 500;
            border: none;
            outline: none;
            cursor: pointer;
        }
    }
`

export default Popup
