import React from "react";
import commonStyles from "@styles/common";
import { css } from "@emotion/react";
import propTypes from "prop-types";

Tabs.propTypes = {
  isChange: propTypes.number,
  setIsChange: propTypes.func,
};

export default function Tabs({ isChange, setIsChange }) {
  const handleClick = (e) => {
    switch (e.target.className) {
      case "btn1":
        setIsChange(0);
        break;
      case "btn2":
        setIsChange(1);
        break;
      case "btn3":
        setIsChange(2);
        break;
      case "btn4":
        setIsChange(3);
        break;
      case "btn5":
        setIsChange(4);
        break;
      case "btn6":
        setIsChange(5);
        break;
      case "btn7":
        setIsChange(6);
        break;
      default:
        setIsChange(0);
    }
  };

  return (
    <nav css={tabs({ isChange })}>
      <button type="button" className="btn1" onClick={handleClick}>
        DCS
      </button>
      <button type="button" className="btn2" onClick={handleClick}>
        Deplay
      </button>
      <button type="button" className="btn3" onClick={handleClick}>
        Petcare Lab
      </button>
      <button type="button" className="btn4" onClick={handleClick}>
        Metaglobe
      </button>
      <button type="button" className="btn5" onClick={handleClick}>
        P2P Exchange
      </button>
      <button type="button" className="btn6" onClick={handleClick}>
        Coin Shoppingmall
      </button>
      <button type="button" className="btn7" onClick={handleClick}>
        Mining
      </button>
    </nav>
  );
}

const { colors, font } = commonStyles;

const tabs = ({ isChange }) => css`
  border-bottom: 1px solid ${colors.lightGray};
  display: flex;
  justify-content: space-between;

  button {
    display: inline-block;
    text-align: center;
    color: ${colors.lightGray};
    padding: 14px;
    position: relative;

    ::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 4px;
      background-color: ${colors.lightBlue};
      display: none;
    }

    &:hover {
      color: ${colors.black};
      font-weight: ${font.weight.bold};

      ::after {
        display: block;
      }
    }

    &.btn1 {
      color: ${isChange === 0 && colors.black};
      font-weight: ${isChange === 0 && font.weight.bold};

      ::after {
        display: ${isChange === 0 && "block"};
      }
    }

    &.btn2 {
      color: ${isChange === 1 && colors.black};
      font-weight: ${isChange === 1 && font.weight.bold};
      ::after {
        display: ${isChange === 1 && "block"};
      }
    }

    &.btn3 {
      color: ${isChange === 2 && colors.black};
      font-weight: ${isChange === 2 && font.weight.bold};
      ::after {
        display: ${isChange === 2 && "block"};
      }
    }

    &.btn4 {
      color: ${isChange === 3 && colors.black};
      font-weight: ${isChange === 3 && font.weight.bold};
      ::after {
        display: ${isChange === 3 && "block"};
      }
    }

    &.btn5 {
      color: ${isChange === 4 && colors.black};
      font-weight: ${isChange === 4 && font.weight.bold};
      ::after {
        display: ${isChange === 4 && "block"};
      }
    }

    &.btn6 {
      color: ${isChange === 5 && colors.black};
      font-weight: ${isChange === 5 && font.weight.bold};
      ::after {
        display: ${isChange === 5 && "block"};
      }
    }

    &.btn7 {
      color: ${isChange === 6 && colors.black};
      font-weight: ${isChange === 6 && font.weight.bold};
      ::after {
        display: ${isChange === 6 && "block"};
      }
    }
  }

  @media screen and (max-width: 767px) {
    border-bottom: none;
    flex-wrap: wrap;

    button {
      width: calc(100% / 3);
      border: 1px solid ${colors.lightGray};
      background-color: ${colors.white};
      padding: 20px 10px;

      &:hover {
        color: ${colors.white};
        font-weight: ${font.weight.bold};
        background-color: ${colors.lightBlue};
      }

      &.btn1 {
        color: ${isChange === 0 && colors.white};
        font-weight: ${isChange === 0 && font.weight.bold};
        background-color: ${isChange === 0 && colors.lightBlue};
        border-bottom: none;
      }

      &.btn2 {
        color: ${isChange === 1 && colors.white};
        font-weight: ${isChange === 1 && font.weight.bold};
        background-color: ${isChange === 1 && colors.lightBlue};
        border-left: none;
        border-right: none;
        border-bottom: none;
      }

      &.btn3 {
        color: ${isChange === 2 && colors.white};
        font-weight: ${isChange === 2 && font.weight.bold};
        background-color: ${isChange === 2 && colors.lightBlue};
        border-bottom: none;
      }

      &.btn4 {
        color: ${isChange === 3 && colors.white};
        font-weight: ${isChange === 3 && font.weight.bold};
        background-color: ${isChange === 3 && colors.lightBlue};
      }

      &.btn5 {
        color: ${isChange === 4 && colors.white};
        font-weight: ${isChange === 4 && font.weight.bold};
        background-color: ${isChange === 4 && colors.lightBlue};
        border-left: none;
        border-right: none;
      }

      &.btn6 {
        color: ${isChange === 5 && colors.white};
        font-weight: ${isChange === 5 && font.weight.bold};
        background-color: ${isChange === 5 && colors.lightBlue};
      }

      &.btn7 {
        color: ${isChange === 6 && colors.white};
        font-weight: ${isChange === 6 && font.weight.bold};
        background-color: ${isChange === 6 && colors.lightBlue};
        border-top: none;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    button {
      font-size: 18px;
    }
  }
`;
