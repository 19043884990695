import { useMediaQuery } from "react-responsive";

export function Mobile({ children }) {
  const isMobile = useMediaQuery({ maxWidth: 425 });
  return isMobile && children;
}

export function BigMobile({ children }) {
  const isBigMobile = useMediaQuery({ maxWidth: 767 });
  return isBigMobile && children;
}

export function MobileOrTablet({ children }) {
  const isMobileOrTablet = useMediaQuery({ maxWidth: 1023 });
  return isMobileOrTablet && children;
}

export function Tablet({ children }) {
  const isTablet = useMediaQuery({ minWidth: 768 });
  return isTablet && children;
}

export function Laptop({ children }) {
  const isLaptop = useMediaQuery({ minWidth: 1024 });
  return isLaptop && children;
}

export function TabletOrLaptop({ children }) {
  const isTabletOrLaptop = useMediaQuery({ maxWidth: 1439 });
  return isTabletOrLaptop && children;
}

export function BigLaptop({ children }) {
  const BigLaptop = useMediaQuery({ minWidth: 1440 });
  return BigLaptop && children;
}

export function Desktop({ children }) {
  const isDesktop = useMediaQuery({ minWidth: 1920 });
  return isDesktop && children;
}
