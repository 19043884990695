import React from "react";
import bg01 from "@images/mobile/main_img.jpg";
import bg02 from "@images/main_img.jpg";
// pdf파일 새 버전으로 업로드 230425
// import whitePaperKR from "@files/whitepaper(KR).pdf";
// import whitePaperEN from "@files/whitepaper(EN).pdf";
import whitePaperKR from "@files/230228 [AIDAPPS] AGC Whitepaper_v1.3(KR).pdf";
import whitePaperEN from "@files/230308 [AIDAPPS] AGC Whitepaper_v1.3(EN).pdf";
import { MobileOrTablet, Laptop, BigLaptop } from "@utils/responsive";
import commonStyles from "@styles/common";
import { css, keyframes } from "@emotion/react";

export default function Visual() {
  return (
    <section css={visual}>
      <div css={inner}>
        <p css={desc}>
          {/* <MobileOrTablet>AGC is the smartest solution combines</MobileOrTablet>
          <Laptop>
            AGC is the smartest
            <br />
            solution combines
          </Laptop>
          <strong>blockchain and AI</strong> */}
          AGC is the platform construction project that combines{" "}
          <strong>blockchain and AI</strong> technology on a{" "}
          <strong>distributed cloud environment.</strong>
        </p>
        {/* <p css={subDesc}>
          AGC aims at AI blockchain based on a<br />
          distributed cloud network environment.
        </p> */}
        <div css={download}>
          <a href={whitePaperKR} target="_blank" rel="noopenner noreferrer">
            Whitepaper (KR)
          </a>
          <a href={whitePaperEN} target="_blank" rel="noopenner noreferrer">
            Whitepaper (EN)
          </a>
        </div>
      </div>
      <BigLaptop>
        <div css={scroll}></div>
      </BigLaptop>
    </section>
  );
}

const { font, colors, transition } = commonStyles;

const visual = css`
  background-image: url(${bg01});
  background-repeat: no-repeat;
  padding: 110px 20px 0;
  height: 100vh;
  position: relative;

  @media screen and (max-width: 767px) {
    background-position: center bottom;
    background-size: contain;
  }

  @media screen and (min-width: 768px) {
    background-image: url(${bg02});
    background-size: cover;
    height: auto;
    padding: 110px 20px;
  }

  @media screen and (min-width: 1440px) {
    height: 800px;
    background-position: center;
    display: flex;
    align-items: center;
  }
`;

const inner = css`
  width: min(100%, 1200px);
  margin: 0 auto;
  text-align: center;

  @media screen and (min-width: 768px) {
    text-align: left;
  }
`;

const desc = css`
  width: min(90%, 320px);
  font-size: 25px;
  font-weight: ${font.weight.light};
  line-height: 1.3;
  margin: 0 auto 20px;

  @media screen and (min-width: 768px) {
    margin: 0 0 20px;
  }

  @media screen and (min-width: 1440px) {
    width: 53%;
    font-size: 50px;
    font-weight: ${font.weight.thin};
  }
`;

// const subDesc = css`
//   width: min(100%, 286px);
//   font-size: 14px;
//   font-weight: ${font.weight.light};
//   color: ${colors.gray};
//   margin: 0 auto 20px;

//   @media screen and (min-width: 768px) {
//     margin: 0 0 20px;
//   }

//   @media screen and (min-width: 1440px) {
//     width: 100%;
//     font-size: 20px;
//     line-height: 1.6;
//     margin-bottom: 40px;
//   }
// `;

const download = css`
  display: grid;
  place-content: center;

  @media screen and (min-width: 768px) {
    display: flex;
    place-content: start;
  }

  a {
    width: 200px;
    height: 50px;
    display: grid;
    place-content: center;
    color: ${colors.white};
    border-radius: 25px;
    background: linear-gradient(
      to right,
      ${colors.lightGreen},
      ${colors.purple}
    );
    transition: ${transition};

    :hover {
      background: ${colors.indigo};
    }

    :not(:last-child) {
      margin-bottom: 14px;
    }

    @media screen and (min-width: 768px) {
      :not(:last-child) {
        margin-right: 24px;
      }
    }

    @media screen and (min-width: 1024px) {
      width: 220px;
      height: 60px;
      border-radius: 30px;
    }
  }
`;

const aniScroll = keyframes`
  from {
    opacity: 1;
    transform: translate(-50%, 0);
  }

  to {
    opacity: 0;
    transform: translate(-50%, 10px);
  }
`;

const scroll = css`
  width: 20px;
  height: 30px;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  border: 2px solid ${colors.black};
  border-radius: 9px;

  ::before {
    content: "SCROLL";
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    font-weight: ${font.weight.medium};
  }

  ::after {
    content: "";
    width: 3px;
    height: 6px;
    position: absolute;
    top: 4px;
    left: 50%;
    border: 2px solid ${colors.black};
    border-radius: 9px;
    animation: ${aniScroll} 2s infinite;
  }
`;
