import React, { useRef } from "react";
import { Home } from "@pages";
import { Header, Footer } from "@components";
import { css } from "@emotion/react";

export default function Container() {
  const menuRef = useRef([]);

  return (
    <div css={container}>
      <Header menuRef={menuRef} />
      <main>
        <Home menuRef={menuRef} />
      </main>
      <Footer />
    </div>
  );
}

const container = css``;
