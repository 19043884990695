import React from "react";
import Title from "./Title";
import commonStyles from "@styles/common";
import { css } from "@emotion/react";
import propTypes from "prop-types";
import img01 from "@images/partners/p_logo_1.jpg";
import img02 from "@images/partners/p_logo_2.jpg";
import img03 from "@images/partners/p_logo_3.jpg";
import img04 from "@images/partners/p_logo_4.jpg";
import img05 from "@images/partners/p_logo_5.jpg";
import img06 from "@images/partners/p_logo_6.jpg";
import img07 from "@images/partners/p_logo_7.jpg";
import img08 from "@images/partners/p_logo_8.jpg";
import img09 from "@images/partners/p_logo_9.jpg";
import img10 from "@images/partners/p_logo_10.jpg";
import img11 from "@images/partners/p_logo_11.jpg";
import img12 from "@images/partners/p_logo_12.jpg";
import img13 from "@images/partners/p_logo_13.jpg";
import { useMediaQuery } from "react-responsive";

Partners.propTypes = {
  menuRef: propTypes.object,
};

export default function Partners({ menuRef }) {
  const column1 = useMediaQuery({ maxWidth: 425 });
  const column2 = useMediaQuery({ minWidth: 635, maxWidth: 934 });
  const column3 = useMediaQuery({ minWidth: 935, maxWidth: 1234 });
  const column4 = useMediaQuery({ minWidth: 1235 });

  return (
    <section
      css={partners}
      id="partners"
      ref={(el) => (menuRef.current[7] = el)}
    >
      <div css={inner}>
        <Title title="Partners" subTitle="We're the best partners with AGC" />
        <div css={items}>
          <div css={item}>
            <img src={img01} alt="Tium investment partners" />
          </div>
          <div css={item}>
            <img src={img02} alt="Protocol Labs" />
          </div>
          <div css={item}>
            <img src={img03} alt="Filecoin Foundation" />
          </div>
          <div css={item}>
            <img src={img04} alt="PROBIT" />
          </div>
          <div css={item}>
            <img src={img05} alt="GENESIS1" />
          </div>
          <div css={item}>
            <img src={img06} alt="METASAN" />
          </div>
          <div css={item}>
            <img src={img07} alt="JUN&FRIENS" />
          </div>
          <div css={item}>
            <img src={img08} alt="PetCare Lab" />
          </div>
          <div css={item}>
            <img src={img09} alt="Comfort&Custom" />
          </div>
          <div css={item}>
            <img src={img10} alt="GOTH DESIGN" />
          </div>
          <div css={item}>
            <img src={img11} alt="weclipse" />
          </div>
          <div css={item}>
            <img src={img12} alt="YOUTH FORUM" />
          </div>
          <div css={item}>
            <img src={img13} alt="한국NFT공인인증원" />
          </div>
          {column1 && <div css={item} className="empty"></div>}
          {column2 && <div css={item} className="empty"></div>}
          {column3 && (
            <>
              <div css={item} className="empty"></div>
              <div css={item} className="empty"></div>
            </>
          )}
          {column4 && (
            <>
              <div css={item} className="empty"></div>
              <div css={item} className="empty"></div>
              <div css={item} className="empty"></div>
            </>
          )}
        </div>
      </div>
    </section>
  );
}

const { colors } = commonStyles;

const partners = css`
  padding: 45px 20px;
  background-color: ${colors.darkWhite};

  @media screen and (min-width: 1024px) {
    padding: 130px 20px;
  }
`;

const inner = css`
  width: min(100%, 1200px);
  margin: 0 auto;
`;

const items = css`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const item = css`
  width: 280px;
  height: 130px;
  background-color: ${colors.white};
  border-radius: 20px;
  flex: auto;
  display: grid;
  place-items: center;

  &.empty {
    background-color: transparent;
    pointer-events: none;
  }

  @media screen and (max-width: 425px) {
    width: 160px;
    height: 60px;
    border-radius: 15px;
    flex: 120px;

    :nth-of-type(7) {
      img {
        width: 30%;
      }
    }

    img {
      width: 50%;
    }
  }
`;
