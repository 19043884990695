import React, { useState } from "react";
import Title from "../Title";
import Tabs from "./Tabs";
import Content from "./Content";
import commonStyles from "@styles/common";
import bgLogo from "@images/bg_logo.png";
import { css } from "@emotion/react";
import propTypes from "prop-types";

Business.propTypes = {
  menuRef: propTypes.object,
};

export default function Business({ menuRef }) {
  const [isChange, setIsChange] = useState(0);
  return (
    <section
      css={business}
      id="business"
      ref={(el) => (menuRef.current[2] = el)}
    >
      <div css={inner}>
        <Title title="Business" subTitle="AGC Business Model" />
        <div css={items}>
          <Tabs isChange={isChange} setIsChange={setIsChange} />
          <Content isChange={isChange} />
        </div>
        <img src={bgLogo} alt="logo" className="bgLogo" />
      </div>
    </section>
  );
}

const { colors } = commonStyles;

const business = css`
  padding: 0 20px;
  background-color: ${colors.darkWhite};
  position: relative;
`;

const inner = css`
  width: min(100%, 1200px);
  margin: 0 auto;
  position: relative;
  padding: 45px 0;

  img.bgLogo {
    position: absolute;
    bottom: -117px;
    left: 0;
  }

  @media screen and (max-width: 767px) {
    img.bgLogo {
      width: 190px;
      bottom: -59px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    img.bgLogo {
      width: 240px;
      bottom: -75px;
      z-index: -1;
    }
  }

  @media screen and (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    padding: 130px 0;
  }
`;

const items = css`
  @media screen and (min-width: 1024px) {
    margin-left: 60px;
    width: min(100%, 800px);
  }
`;
