import React from "react";
import commonStyles from "@styles/common";
import Title from "./Title";
import img01 from "@images/roadmap_img.png";
import img02 from "@images/mobile/roadmap_img.jpg";
import { MobileOrTablet, Laptop } from "@utils/responsive";
import { css } from "@emotion/react";
import propTypes from "prop-types";

RoadMap.propTypes = {
  menuRef: propTypes.object,
};

export default function RoadMap({ menuRef }) {
  return (
    <section css={roadMap} id="roadMap" ref={(el) => (menuRef.current[9] = el)}>
      <div css={inner}>
        <Title title="Road map" subTitle="AGC Estimated Roadmap" />
        <MobileOrTablet>
          <img src={img02} alt="Road Map" />
        </MobileOrTablet>

        <Laptop>
          <img src={img01} alt="Road Map" />
        </Laptop>
      </div>
    </section>
  );
}

const { colors, font } = commonStyles;

const roadMap = css`
  padding: 45px 20px;

  @media screen and (min-width: 1024px) {
    padding: 130px 20px;
  }
`;

const inner = css`
  width: min(100%, 1200px);
  margin: 0 auto;

  img {
    margin: 80px 0 0;
  }

  @media screen and (max-width: 1023px) {
    img {
      margin: 0;
    }
  }
`;
