import React from "react";
import Title from "../Title";
import commonStyles from "@styles/common";
import Carousel from "./Carousel";
import { css } from "@emotion/react";
import propTypes from "prop-types";

Service.propTypes = {
  menuRef: propTypes.object,
};

export default function Service({ menuRef }) {
  return (
    <section css={service} id="service" ref={(el) => (menuRef.current[4] = el)}>
      <div css={inner}>
        <Title
          title="Service"
          subTitle="AGC Coin is used as a platform coin 
throughout the AIDAPS platform, 
and the value of AGC Coin and Ai-Dapps
stocksincreases as the number of users 
on each platform increases."
        />
        <Carousel />
      </div>
    </section>
  );
}

const { colors } = commonStyles;

const service = css`
  padding: 45px 20px;
  background-color: ${colors.lightestBlue};

  @media screen and (min-width: 1024px) {
    padding: 130px 20px;
  }
`;

const inner = css`
  width: min(100%, 1200px);
  margin: 0 auto;

  @media screen and (min-width: 1024px) {
    display: flex;
    justify-content: space-between;

    .slick-slider {
      width: 75%;
      margin-left: 20px;
    }
  }
`;
