import React from "react";
import { css } from "@emotion/react";
import propTypes from "prop-types";
import commonStyles from "@styles/common";

AsideMenu.propTypes = {
  isOpen: propTypes.bool,
  setIsOpen: propTypes.func,
  handleClick: propTypes.func,
  selectedMenu: propTypes.number,
};

export default function AsideMenu({
  isOpen,
  setIsOpen,
  handleClick,
  selectedMenu,
}) {
  return (
    <aside css={aside({ isOpen })}>
      <ul css={list}>
        {menuItem.map((item, i) => (
          <li key={item.id} href="/" css={listItem({ selectedMenu })}>
            <button
              type="button"
              onClick={() => {
                handleClick(i);
                setIsOpen(false);
              }}
            >
              {item.title}
            </button>
          </li>
        ))}
      </ul>
      <div
        css={bg}
        onClick={() => {
          setIsOpen(false);
        }}
      ></div>
    </aside>
  );
}
const { colors, transition, font } = commonStyles;

const menuItem = [
  { id: 0, title: "About AGC" },
  { id: 1, title: "BSC" },
  { id: 2, title: "Business Model" },
  { id: 3, title: "Ecosystem" },
  { id: 4, title: "Service" },
  { id: 5, title: "News" },
  { id: 6, title: "Relationships" },
  { id: 7, title: "Partners" },
  { id: 8, title: "Community" },
  { id: 9, title: "Road map" },
];

const aside = ({ isOpen }) => css`
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  z-index: 9;
  visibility: ${isOpen ? "visible" : "hidden"};
  transition: ${transition};
  opacity: ${isOpen ? 1 : 0};
`;

const list = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 9;
  background-color: ${colors.white};
  padding: 40px 0;
  overflow: auto;
`;

const listItem = ({ selectedMenu }) => css`
  position: relative;

  :first-of-type {
    ::after {
      content: "";
      width: ${selectedMenu === 0 && "100%"};
    }
  }

  :nth-of-type(2) {
    ::after {
      content: "";
      width: ${selectedMenu === 1 && "100%"};
    }
  }

  :nth-of-type(3) {
    ::after {
      content: "";
      width: ${selectedMenu === 2 && "100%"};
    }
  }

  :nth-of-type(4) {
    ::after {
      content: "";
      width: ${selectedMenu === 3 && "100%"};
    }
  }

  :nth-of-type(5) {
    ::after {
      content: "";
      width: ${selectedMenu === 4 && "100%"};
    }
  }

  :nth-of-type(6) {
    ::after {
      content: "";
      width: ${selectedMenu === 5 && "100%"};
    }
  }

  :nth-of-type(7) {
    ::after {
      content: "";
      width: ${selectedMenu === 6 && "100%"};
    }
  }

  :nth-of-type(8) {
    ::after {
      content: "";
      width: ${selectedMenu === 7 && "100%"};
    }
  }

  :nth-of-type(9) {
    ::after {
      content: "";
      width: ${selectedMenu === 8 && "100%"};
    }
  }

  :last-child {
    ::after {
      content: "";
      width: ${selectedMenu === 9 && "100%"};
    }
  }

  ::after {
    content: "";
    width: 0;
    height: 4px;
    position: absolute;
    bottom: -10px;
    left: 0;
    background-color: ${colors.lightBlue};
    transition: width ${transition};
  }

  :not(:last-child) {
    margin-bottom: 50px;
  }

  button {
    font-size: 25px;
    font-weight: ${font.weight.bold};
    color: ${colors.black};
  }

  @media screen and (max-width: 1439px) {
    :not(:last-child) {
      margin-bottom: 24px;
    }

    button {
      font-size: 20px;
    }
  }
`;

const bg = css`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 8;
  background-color: rgba(0, 0, 0, 0.6);
`;
