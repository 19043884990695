import React from "react";
import { css } from "@emotion/react";
import propTypes from "prop-types";
import commonStyles from "@styles/common";

Title.propTypes = {
  title: propTypes.string.isRequired,
  subTitle: propTypes.string.isRequired,
  titleColor: propTypes.string,
  subTitleColor: propTypes.string,
};

export default function Title({ title, subTitle, titleColor, subTitleColor }) {
  return (
    <div css={titleGroup({ titleColor, subTitleColor })}>
      <h2>{title}</h2>
      <span>{subTitle}</span>
    </div>
  );
}

const { colors, font } = commonStyles;

const titleGroup = ({ titleColor, subTitleColor }) => css`
  text-align: center;
  margin-bottom: 35px;

  h2 {
    font-size: 32px;
    font-weight: ${font.weight.black};
    color: ${titleColor ? titleColor : colors.black};
    margin-bottom: 10px;
  }

  span {
    font-size: 15px;
    color: ${subTitleColor ? subTitleColor : colors.lightGray};
  }

  @media screen and (min-width: 1024px) {
    text-align: left;

    h2 {
      font-size: 62px;
    }

    span {
      font-size: 16px;
      line-height: 1.6;
    }
  }
`;
