import React from "react";
import commonStyles from "@styles/common";
import Title from "./Title";
import bg from "@images/ecosystem_bn.jpg";
import { MdCheck } from "react-icons/md";
import { css } from "@emotion/react";
import propTypes from "prop-types";

Ecosystem.propTypes = {
  menuRef: propTypes.object,
};

export default function Ecosystem({ menuRef }) {
  return (
    <section
      css={ecosystem}
      id="ecosystem"
      ref={(el) => (menuRef.current[3] = el)}
    >
      <div css={inner}>
        <Title title="Ecosystem" subTitle="AGC Ecosystem Configuration" />
        <img src={bg} alt="AGC Ecosystem Configuration" />
        <p>
          As shown in the figure below, the entire ecosystem of AIDAPPS (AIDAPPS
          Group Cryptocurrency) project is all connected with AGC Token and
          sub-AIDAPPS Platform. When AGC holders acquire AGC Tokens, they will
          receive an airdrop of tokens from the corresponding platform at the
          same rate according to the holding ratio of AGC Tokens, thereby
          providing benefits to the entire AIDAPPS group upon the launch of
          AIDAPPS Platform in the future. AGC token is a platform token and
          swappable utility token to be launched in the future, and it is used
          for real estate NFT investment, pet care service, and product purchase
          on various platforms, so the value of AGC tokens increases as each
          platform grows and the number of users increases.
        </p>
        <div className="item">
          <label>
            Ecosystem <span>Cycling</span>
          </label>
          <ul>
            <li>
              <span>
                <MdCheck />
              </span>
              <p>AGC holder purchases AGC coins through a block deal</p>
            </li>
            <li>
              <span>
                <MdCheck />
              </span>
              <p>AGC Coin is used as a platform coin on AIDAPPS platform</p>
            </li>
            <li>
              <span>
                <MdCheck />
              </span>
              <p>
                Afterwards, each platform token is acquired by the percentage of
                ownership when AIDAPPS launches its platform
              </p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

const { colors, font } = commonStyles;

const ecosystem = css`
  padding: 65px 20px;

  @media screen and (min-width: 1024px) {
    padding: 130px 20px;
  }
`;

const inner = css`
  width: min(100%, 1200px);
  margin: 0 auto;

  img {
    margin: 0 auto 24px;
  }

  & > p {
    font-size: 15px;
    font-weight: ${font.weight.light};
    line-height: 1.4;
    margin-bottom: 24px;
  }

  .item {
    background-color: ${colors.darkWhite};
    border-radius: 30px;
    padding: 20px;

    label {
      font-size: 20px;
      font-weight: ${font.weight.bold};
      text-align: center;
      width: 100%;
      margin-bottom: 24px;
    }

    ul {
      li {
        display: flex;

        :not(:last-child) {
          p {
            margin-bottom: 20px;
          }
        }

        span {
          margin-right: 10px;
          color: ${colors.lightBlue};
          font-size: 18px;
        }

        p {
          font-size: 14px;
          font-weight: ${font.weight.light};
        }
      }
    }
  }

  @media screen and (min-width: 1024px) {
    img {
      margin-bottom: 50px;
    }

    & > p {
      font-size: 20px;
      margin-bottom: 40px;
    }

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 20px;
      padding: 50px 80px;

      label {
        font-size: 36px;
        font-weight: ${font.weight.thin};
        text-align: center;
        width: auto;
        margin-bottom: 24px;
        margin-right: 40px;

        span {
          display: block;
          font-weight: ${font.weight.bold};
          margin-top: 10px;
        }
      }

      ul {
        li {
          :not(:last-child) {
            p {
              margin-bottom: 26px;
            }
          }

          span {
            margin-right: 14px;
            font-size: 24px;
          }

          p {
            font-size: 20px;
          }
        }
      }
    }
  }
`;
