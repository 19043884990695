import React, { useEffect, useState } from "react";
import { AsideMenu } from "@components";
import logo from "@images/logo.png";
import commonStyles from "@styles/common";
import { TabletOrLaptop, BigLaptop } from "@utils/responsive";
import { css } from "@emotion/react";
import propTypes from "prop-types";

Header.propTypes = {
  menuRef: propTypes.object,
};

export default function Header({ menuRef }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isScroll, setIsScroll] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const observerCallback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && 0 < entry.intersectionRatio) {
        const index = sectionIds.indexOf(`#${entry.target.id}`);
        setSelectedMenu(index);
      }
    });
  };

  const observerOption = {
    root: null,
    rootMargin: "0px",
    threshold: 0.3,
  };

  const observer = new IntersectionObserver(observerCallback, observerOption);

  useEffect(() => {
    menuRef.current.forEach((el) => {
      observer.observe(el);
    });

    window.addEventListener("wheel", () => {
      if (window.scrollY === 0) {
        setSelectedMenu(null);
      }
    });

    if (window.scrollY === 0) {
      setSelectedMenu(null);
    }
  }, [observer, menuRef]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (200 < window.scrollY) {
        setIsScroll(true);
      } else {
        setIsScroll(false);
      }
    });
  }, [window.scrollY]);

  const handleClick = (i) => {
    menuRef.current[i].scrollIntoView({
      behavior: "smooth",
      block: i === 9 ? "start" : "center",
    });
  };

  return (
    <>
      <header css={header({ isScroll })}>
        <div css={inner}>
          <a href="/">
            <img src={logo} alt="logo" />
          </a>
          <TabletOrLaptop>
            <button
              css={hamburger({ isOpen })}
              type="button"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              <span className="line line1"></span>
              <span className="line line2"></span>
              <span className="line line3"></span>
            </button>
          </TabletOrLaptop>
          <BigLaptop>
            <ul css={list}>
              {menuItem.map((item, i) => (
                <li key={item.id} css={listItem({ selectedMenu })}>
                  <button
                    type="button"
                    onClick={() => {
                      handleClick(i);
                      setSelectedMenu(i);
                    }}
                  >
                    {item.title}
                  </button>
                </li>
              ))}
            </ul>
          </BigLaptop>
        </div>
      </header>
      <TabletOrLaptop>
        <AsideMenu
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          menuRef={menuRef}
          handleClick={handleClick}
          selectedMenu={selectedMenu}
        />
      </TabletOrLaptop>
    </>
  );
}

const menuItem = [
  { id: 0, title: "About AGC" },
  { id: 1, title: "BSC" },
  { id: 2, title: "Business Model" },
  { id: 3, title: "Ecosystem" },
  { id: 4, title: "Service" },
  { id: 5, title: "News" },
  { id: 6, title: "Relationships" },
  { id: 7, title: "Partners" },
  { id: 8, title: "Community" },
  { id: 9, title: "Road map" },
];

const sectionIds = [
  "#about",
  "#bsc",
  "#business",
  "#ecosystem",
  "#service",
  "#news",
  "#relationships",
  "#partners",
  "#community",
  "#roadMap",
];

const { colors, transition } = commonStyles;

const header = ({ isScroll }) => css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  box-shadow: ${isScroll ? "3px 4px 5px 0px rgba(0, 0, 0, 0.05)" : "none"};
  background-color: ${colors.white};
  padding: 0 20px;
  transition: ${transition};

  @media screen and (max-width: 1023px) {
    height: 70px;
  }

  @media screen and (min-width: 1024px) {
    height: 80px;
  }
`;

const inner = css`
  width: min(100%, 1780px);
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const hamburger = ({ isOpen }) => css`
  .line {
    width: 30px;
    height: 4px;
    display: block;
    background-color: ${colors.black};
    transition: ${transition};

    :not(:last-child) {
      margin-bottom: 5px;
    }

    &.line1 {
      transform: translateY(${isOpen ? 8 : 0}px) rotate(${isOpen ? -45 : 0}deg);
    }

    &.line2 {
      opacity: ${isOpen ? 0 : 1};
      visibility: ${isOpen ? "hidden" : "visible"};
    }

    &.line3 {
      transform: translateY(${isOpen ? -10 : 0}px) rotate(${isOpen ? 45 : 0}deg);
    }
  }
`;

const list = css``;

const listItem = ({ selectedMenu }) => css`
  position: relative;
  display: inline-block;

  :first-of-type {
    ::after {
      content: "";
      width: ${selectedMenu === 0 && "100%"};
    }
  }

  :nth-of-type(2) {
    ::after {
      content: "";
      width: ${selectedMenu === 1 && "100%"};
    }
  }

  :nth-of-type(3) {
    ::after {
      content: "";
      width: ${selectedMenu === 2 && "100%"};
    }
  }

  :nth-of-type(4) {
    ::after {
      content: "";
      width: ${selectedMenu === 3 && "100%"};
    }
  }

  :nth-of-type(5) {
    ::after {
      content: "";
      width: ${selectedMenu === 4 && "100%"};
    }
  }

  :nth-of-type(6) {
    ::after {
      content: "";
      width: ${selectedMenu === 5 && "100%"};
    }
  }

  :nth-of-type(7) {
    ::after {
      content: "";
      width: ${selectedMenu === 6 && "100%"};
    }
  }

  :nth-of-type(8) {
    ::after {
      content: "";
      width: ${selectedMenu === 7 && "100%"};
    }
  }

  :nth-of-type(9) {
    ::after {
      content: "";
      width: ${selectedMenu === 8 && "100%"};
    }
  }

  :last-child {
    ::after {
      content: "";
      width: ${selectedMenu === 9 && "100%"};
    }
  }

  ::after {
    content: "";
    width: 0;
    height: 4px;
    position: absolute;
    bottom: -10px;
    left: 0;
    background-color: ${colors.lightBlue};
    transition: width ${transition};
  }

  :hover::after {
    content: "";
    width: 100%;
  }

  :not(:last-child) {
    margin-right: 55px;
  }

  button {
    font-size: 16px;
  }
`;
