import React from "react";
import Title from "./Title";
import img01 from "@images/BSC_bn_01.png";
import img02 from "@images/BSC_bn_02.png";
import img03 from "@images/BSC_bn_03.png";
import img04 from "@images/BSC_bn_04.png";
import commonStyles from "@styles/common";
import { css } from "@emotion/react";
import { MobileOrTablet, Laptop } from "@utils/responsive";
import propTypes from "prop-types";

Bsc.propTypes = {
  menuRef: propTypes.object,
};

export default function Bsc({ menuRef }) {
  return (
    <section css={bsc} id="bsc" ref={(el) => (menuRef.current[1] = el)}>
      <div css={inner}>
        <Title
          title="BSC : Binance Mainnet Smart Chain"
          subTitle="The Metaverse Blockchain For All"
        />
        <div css={items}>
          <div css={item}>
            <div>
              <span>01</span>
              <h3>Excellent performance</h3>
              <MobileOrTablet>
                <p>
                  Mainchain ensures block creation time of 1 second and instant
                  transaction completeness
                </p>
              </MobileOrTablet>
              <Laptop>
                <p>
                  New blockchain with all functions for developing
                  high-performance decentralized applications. Built for
                  cross-chain compatibility with BinanceChain with users benefit
                  from both blockchains. and As a high-performance decen
                  tralized blockchain, it works with the Ethereum Virtual
                  Machine (EVM) in conjunction with BNB Staking to support
                  Ethereum-based tools and DApps, and runs DApps on the Binance
                  Smart Chain.
                </p>
              </Laptop>
            </div>
            <img src={img01} alt="Superior performance" />
          </div>
          <div css={item}>
            <div>
              <span>02</span>
              <h3>Flexibility</h3>
              <MobileOrTablet>
                <p>Service operators can pay user transaction fees instead</p>
              </MobileOrTablet>
              <Laptop>
                <p>
                  Applications such as Pancake Swap allow users to exchange
                  assets without the certification process, participate in
                  staking, and vote on proposals. Bridge projects to increase
                  interoperability between different blockchains allow users to
                  convert specific coins used on the Binance Chain and the
                  Binance Smart Chain into wrapped tokens (or "pegged tokens")
                  Digital assets such as BTC, ETH, USDT, LTC, XRP, LINK, ATOM,
                  DOT, XTZ, ONT are used in the Binance Chain ecosystem Wallets.
                </p>
              </Laptop>
            </div>
            <img src={img02} alt="Great user experience" />
          </div>
          <div css={item}>
            <div>
              <span>03</span>
              <h3>Strong Blockchain Platform</h3>
              <MobileOrTablet>
                <p>
                  Klaytn is the largest example, with 66% of newly issued tokens
                  reinvested in the ecosystem
                </p>
              </MobileOrTablet>
              <Laptop>
                <p>
                  Even before the launch of the Binance Smart Chain Mainnet,
                  many major encryption projects have already worked with the
                  Binance Chain community to build BSC as a powerful block chain
                  platform Partners consist of a variety of industries,
                  including blockchain infrastructure and tool providers,
                  distributed financial platforms, and cross-chain liquidity
                  providers.
                </p>
              </Laptop>
            </div>
            <img
              src={img03}
              alt="Full comprehensive eco-fund at protocol level"
            />
          </div>
          <div css={item}>
            <div>
              <span>04</span>
              <h3>Quick development</h3>
              <MobileOrTablet>
                <p>
                  Anyone can request, view, and view transactions on the
                  blockchain
                </p>
              </MobileOrTablet>
              <Laptop>
                <p>
                  A dual-chain structure that is independent of the existing
                  Binance Chain and complementary allows users to freely
                  transfer assets between each block chain, which enables fast
                  trading on the Binance Chain and enables powerful
                  decentralized apps. BEP-2 and BEP-8 tokens of Binance Chain
                  can be swapped with BEP-20 tokens, a new standard introduced
                  by Binance Smart Chain, and BEP-20 tokens use the same
                  functions as Ethereum.
                </p>
              </Laptop>
            </div>
            <img
              src={img04}
              alt="Transparency, Security and Decentralization"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

const { colors, font } = commonStyles;

const bsc = css`
  padding: 70px 20px 45px;

  @media screen and (min-width: 1024px) {
    padding: 130px 20px;
  }
`;

const inner = css`
  width: min(100%, 1200px);
  margin: 0 auto;
`;

const items = css`
  @media screen and (min-width: 768px) {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }
`;

const item = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  :not(:last-child) {
    margin-bottom: 24px;
  }

  div {
    width: max(240px, 70%);
  }

  span {
    font-size: 25px;
    font-weight: ${font.weight.black};
    color: ${colors.lightBlue};
    margin-bottom: 6px;
    display: inline-block;
  }

  h3 {
    font-size: 20px;
    font-weight: ${font.weight.medium};
    margin-bottom: 6px;
  }

  p {
    font-size: 15px;
    font-weight: ${font.weight.light};
    color: ${colors.gray};
    line-height: 1.4;
  }

  @media screen and (min-width: 320px) {
    img {
      width: 90px;
    }
  }

  @media screen and (min-width: 768px) {
    width: 50%;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;

    :nth-last-of-type(2),
    :last-child {
      margin-bottom: 0;
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    h3 {
      text-align: center;
    }

    p {
      text-align: center;
    }

    img {
      margin-bottom: 20px;
      width: 140px;
    }
  }

  @media screen and (min-width: 1440px) {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;

    :not(:last-child) {
      margin-bottom: 60px;
    }

    :nth-of-type(odd) {
      div {
        margin-right: 24px;
      }
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    span {
      font-size: 40px;
    }

    h3 {
      font-size: 24px;
      text-align: left;
    }

    p {
      font-size: 16px;
      text-align: left;
    }

    img {
      width: auto;
      margin: 20px 40px 0 0;
    }
  }
`;
