import React, { useEffect, useState } from "react";
import { MdArrowUpward } from "react-icons/md";
import { css } from "@emotion/react";
import commonStyles from "@styles/common";

export default function ToTop() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (100 < window.scrollY) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    });
  });

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <button type="button" css={toTop({ isVisible })} onClick={handleClick}>
      <MdArrowUpward />
    </button>
  );
}

const { colors, transition } = commonStyles;

const toTop = ({ isVisible }) => css`
  background-color: ${colors.lightBlue};
  width: 45px;
  height: 45px;
  border-radius: 50%;
  color: ${colors.white};
  font-size: 20px;
  display: grid;
  place-content: center;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 11;
  opacity: ${isVisible ? 1 : 0};
  visibility: ${isVisible ? "visible" : "hidden"};
  transform: translateX(${isVisible ? 0 : 40}px);
  transition: ${transition};
`;
